import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetProductsList } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DataTable from "../../../components/Tables/DataTable";
import EditProduct from "./edit-products";

const Products = ({ businessId }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [productsList, setProductsList] = useState([]);
  const [productsId, setProductsId] = useState([]);
  const [openEditProducts, setOpenEditProducts] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsList, { onLoad: false });
  const { pathname } = useLocation();

  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await ProductsListAPICall({
        payload: [{ businessId: `${businessId}`, keyword: `${search}` }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
  }, [loading]);

  eventBus.$on("reloadProductDetailsList", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    setLoading(true);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }
  useEffect(async () => {
    setLoading(true);
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await ProductsListAPICall({
          payload: [{ businessId: `${businessId}`, keyword: `${search}` }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      setTotalRecords(ProductsResponse.data.data.totalRecords);
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        let USDollar = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return {
          name: prop.name,
          productType: prop.productType,
          taxable: prop.taxable === true ? "Yes" : "No",
          unitPrice: USDollar.format(prop.unitPrice),
          createdat: Createdate.toLocaleDateString(),
          action: (
            <MDBox display="flex">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.edittooltip}
                  placement="top"
                  onClick={(value) => {
                    setProductsId(prop._id);
                    setOpenEditProducts(true);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.deletetooltip}
                  placement="top"
                  onClick={(value) => {
                    setProductsId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setProductsList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [ProductsResponse]);

  const dataTableData1 = {
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Product Type",
        accessor: "productType",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "taxable",
        accessor: "taxable",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "unitPrice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: productsList,
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const successDelete = async () => {
    setLoading(true);
    setAlert(false);
  };
  return (
    <>
      <MDBox
        px={2}
        sx={{ pr: 3, mb: 2 }}
        component="form"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h5" fontWeight="medium">
          Products
        </MDTypography>
        <MDBox px={2} display="flex">
          <MDBox sx={{ pr: 3, mb: 2 }}>
            <MDInput
              type="search"
              label="Search Products"
              onKeyDown={handleKeyDown}
              variant="standard"
              onChange={handleSearch}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
      {totalRecords === 0 ? (
        <MDBox my={5} sx={{ mt: 1 }}>
          <DataTable
            table={dataTableData1}
            entriesPerPage={false}
            pagination={false}
            showTotalEntries={true}
          />
          <MDTypography
            variant="body2"
            color="secondary"
            sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "-2%" }}
          >
            No records found
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox my={5} sx={{ mt: 1 }}>
          <DataTable
            table={dataTableData1}
            entriesPerPage={false}
            pagination={false}
            showTotalEntries={true}
          />
        </MDBox>
      )}
      <MDBox display="flex" justifyContent="center">
        {loading && <Loader />}
      </MDBox>
      <MDBox>
        <Dialog
          open={alert}
          onClose={() => setAlert(false)}
          aria-labelledby="alert-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",
              },
            },
          }}
        >
          <DialogContent sx={{ overflowY: "hidden" }}>
            <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <h3>Are you sure?</h3>
            </DialogContentText>
            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
              >
                Once you delete this Products there is no way of getting any of the documents or
                data back.
              </MDTypography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
            <MDButton variant="gradient" color="success" onClick={successDelete}>
              Yes, delete it!
            </MDButton>
            <MDButton variant="gradient" color="error" onClick={() => setAlert(false)}>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openEditProducts}
          onClose={() => setOpenEditProducts(false)}
          aria-labelledby="alert-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "570px",
                minHeight: "30%",
              },
            },
          }}
        >
          {" "}
          <DialogTitle
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            Edit Products
          </DialogTitle>
          <DialogContent>
            <EditProduct
              productId={productsId}
              businessId={businessId}
              onClose={() => setOpenEditProducts(false)}
            />
          </DialogContent>
        </Dialog>
      </MDBox>
    </>
  );
};

export default UserHoc(Products);
