import React, { useState, useEffect, useCallback, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormField from "layouts/admin/accountants/components/FormField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAPICall } from "../../../../utils/useapicall";
import {
  AddNewItem,
  GetCustomerAllList,
  GetClassesAllList,
  GetCategoryAllList,
  GetProductsAllList,
  GetProductList,
  GetCustomerListForParent,
  GetCategoryListForParent,
  GetClassListForParent
} from "../../../../services/userservices";
import Checkbox from "@mui/material/Checkbox";
import { eventBus } from "utils/eventbus";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CurrencyFieldText from "../components/CurrencyField-Text";
import NumberFieldText from "../components/NumberField";
import AddCategoryItemDialog from "./addCategoryItemDialog";
import AddNewProductDialog from "./addnewproduct";
import AddCustomerDialog from "./addnewcustomer";
import AddClassDialog from "./addnewclass";
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { useMaterialUIController } from "context";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
});

function AddItemDialog({
  invoiceId,
  onClose,
  currencySymbol,
  businessId,
  itemList,
  toggleShow,
  showClasses,
}) {
  const styles = useStyles();
  const [invoiceIds, setInvoiceIds] = useState(invoiceId);
  const [isDisable, setBtnDisable] = useState(false);
  const [errorButtonDis, setErrorButtonDis] = useState(false);
  const [value, setValue] = useState("ItemBasedExpenseLineDetail");
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [selectProduct, setSelectProduct] = useState(false);
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productIds, setProductId] = useState("");
  const [classIdIds, setClassIdId] = useState("");
  const [customerIds, setCustomerIdId] = useState("");
  const [producterrormsg, setProducterrormsg] = useState("");
  const [producterror, setProducterror] = useState(false);
  const [adjustInvoice, setAdjustInvoice] = useState(false);
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [price, setPrice] = useState(0);
  const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [defaultValue, setDefaultValue] = useState(false);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [priceerror, setpriceerror] = useState(false);
  const [quantityerror, setQuantityrror] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrorqty, setShowerrorqty] = useState(false);
  const [productName, SetProductName] = useState("");
  const [customerName, SetCustomerName] = useState("");
  const [className, SetClassName] = useState("");
  const [addProductSelectType, setAddProductSelectType] = useState("");
  const [productLoading, setProductLoading] = useState(false);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [productOffset, setProductOffset] = useState(0);
  const [productSearch, setProductSearch] = useState('');
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerOffset, setCustomerOffset] = useState(0);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);
  const [hasMoreClasses, setHasMoreClasses] = useState(true);
  const [classOffset, setClassOffset] = useState(0);
  const [classSearch, setClassSearch] = useState('');
  const limit = 50;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerListAPICall,
  ] = useAPICall(GetCustomerAllList, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesAllList, { onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryAllList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsAllList, { onLoad: false });

  const AddProduct = { name: "+ Add Product" };
  const NewProductList = [AddProduct, ...productsList];
  const AddCustomer = { name: "+ Add Customer" };
  const NewCustomerList = [AddCustomer, ...customerList];
  const AddClass = { name: "+ Add Class" };
  const NewClassesList = [AddClass, ...classesList];

  useEffect(async () => {
    setValue("ItemBasedExpenseLineDetail");
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  const initialValues = {
    product: "",
    checked: "",
    item: description,
    sku: "",
    price: unitPrice,
    total: "",
    qty: "",
    services: "",
    class: "",
    customer: "",
    unit: "",
    category: "",
    TotalPrice: "",
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeAddNewItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          customerId: prop?._id,
        };
      });
      // setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          classId: prop?._id,
        };
      });
      // setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          categoryId: prop?._id,
        };
      });
      // setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          productId: prop?._id,
          accountingId: prop?.accountingId,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
        };
      });
      // setProductsList(data);
    }
  }, [ProductsResponse]);

  const handleSubmit = async (values) => {
    // if (price.length === 0 || Number(price) === 0) {
    //   setPriceErrorMsg("Price is required");
    //   setpriceerror(true);
    // } else {
    //   setPriceErrorMsg("");
    //   setpriceerror(false);
    // }

    // if (quantity.length === 0 || Number(quantity) === 0) {
    //   setQuantityErrorMsg("Quantity is required");
    //   setQuantityrror(true);
    // } else {
    //   setQuantityErrorMsg("");
    //   setQuantityrror(false);
    // }

    if (productIds) {
      setProducterrormsg("");
      setProducterror(false);
    } else {
      setProducterrormsg("Product name required");
      setProducterror(true);
    }
    const finalprice = typeof price == "string" ? price.replaceAll(",", "") : price;
    const finalTotalPrice =
      typeof TotalPrice == "string" ? TotalPrice.replaceAll(",", "") : TotalPrice;
    const finalquantity = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (productIds) {
      setBtnDisable(true);
      await setAPICall({
        payload: [
          {
            itemType: "ItemBasedExpenseLineDetail",
            unitPrice: Number(finalprice),
            amount: Number(finalTotalPrice),
            discount: 0,
            sku: values.sku,
            description: values.item,
            unitOfMeasure: values.unit,
            productId: productIds,
            InvoiceId: `${invoiceIds}`,
            classId: classIdIds,
            customerId: customerIds,
            quantity: Number(finalquantity),
            adjustDocument: adjustInvoice,
          },
        ],
      });
    }
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleOpenAddNewProduct = (data) => {
    setOpenAddNewProduct(true);
    setAddProductSelectType(data);
    setOpenAddOption(false);
  };

  const handleChangeProduct = (event, newValue) => {
    if (newValue == "+ Add Product") {
      setOpenAddOption(true);
    } else {
      if (newValue) {
        setProducterrormsg("");
        setProducterror(false);
      } else {
        setProducterrormsg("Product name required");
        setProducterror(true);
      }
      SetProductName(newValue);
      const found = productsList.find((obj) => {
        return obj.name === newValue;
      });
      if (found) {
        setProductId(found.productId);
        setSelectProduct(true);
        setDescription(found?.description === "---" ? "" : found?.description);
        setUnitPrice(found.unitPrice);
      }
    }
  };

  const handleChangeClass = (event, newValue) => {
    if (newValue == "+ Add Class") {
      setOpenAddNewClass(true);
    } else {
      SetClassName(newValue);
      const found1 = classesList.find((obj) => {
        return obj.name === newValue;
      });
      if (found1) {
        setClassIdId(found1.classId);
      }
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue == "+ Add Customer") {
      setOpenAddNewCustomer(true);
    } else {
      SetCustomerName(newValue);
      const found2 = customerList.find((obj) => {
        return obj.name === newValue;
      });
      if (found2) {
        setCustomerIdId(found2.customerId);
      }
    }
  };

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (showerror === true) {
      // if (!prices) {
      //   setPriceErrorMsg("Price is required");
      //   setpriceerror(true);
      // } else if (prices.length === 0 || Number(prices) === 0) {
      //   setPriceErrorMsg("Price Must be more than 0");
      //   setpriceerror(true);
      // } else {
      //   setPriceErrorMsg("");
      //   setpriceerror(false);
      // }
    }

    if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }

    // if (showerrorqty === true) {
    //   if (!quantitys) {
    //     setQuantityErrorMsg("Quantity is required");
    //     setQuantityrror(true);
    //   } else if (quantitys.length === 0 || Number(quantitys) === 0) {
    //     setQuantityErrorMsg("Quantity Must be more than 0");
    //     setQuantityrror(true);
    //   } else {
    //     setQuantityErrorMsg("");
    //     setQuantityrror(false);
    //   }
    // }

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(() => {
    if (
      price.length === 0 ||
      (Number(price) === 0.0 && quantity.length === 0) ||
      Number(quantity) === 0
    ) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }
  }, []);

  eventBus.$on("AddnewProductComplete", (data) => AddnewProductComplete(data));
  function AddnewProductComplete(data) {
    SetProductName(data?.product?.name);
    setProductId(data?.product?._id);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewCustomerComplete", (data) => AddnewCustomerComplete(data));
  function AddnewCustomerComplete(data) {
    SetCustomerName(data?.customer?.name);
    setCustomerIdId(data?.customer?._id);
    CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewClassComplete", (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    SetClassName(data?.class?.name);
    setClassIdId(data?.class?._id);
    ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  const listboxRef = useRef(null); // Reference for the listbox

  const fetchProducts = useCallback(async (newSearch = '', newOffset = 0) => {
    setProductLoading(true);
    try {
      const response = await GetProductList({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop?.name,
        productId: prop?._id,
        accountingId: prop?.accountingId,
        description: prop?.description,
        unitPrice: prop?.unitPrice,
      }));

      if (newOffset === 0) {
        setProductsList(data); // Reset the list when new search
      } else {
        setProductsList((prev) => [...prev, ...data]); // Append new data for infinite scroll
      }

      if (data.length < 50) {
        setHasMoreProducts(false); // Stop loading more if fewer than 50 items
      }

      setProductOffset(newOffset);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setProductLoading(false);
  }, [businessId]);

  const handleProductScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5;
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreProducts) {
      setProductOffset((prevOffset) => {
        const newOffset = prevOffset + limit; // Increment offset
        fetchProducts(productSearch, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };

  const fetchCustomers = useCallback(async (newSearch = '', newOffset = 0) => {
    setCustomerLoading(true);
    try {
      const response = await GetCustomerListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 customers at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop?.name,
        customerId: prop?._id,
      }));

      // Append new data instead of resetting the list
      if (newOffset === 0) {
        setCustomerList(data);
      } else {
        setCustomerList((prev) => [...prev, ...data]);
      }

      // Stop loading more if fewer than 50 items are returned
      if (data.length < 50) {
        setHasMoreCustomers(false);
      }

      setCustomerOffset(newOffset);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
    setCustomerLoading(false);
  }, [businessId]);

  const handleScrollCustomer = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5; // Adjust if necessary to control sensitivity
    const bottom =
      Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreCustomers) {
      setCustomerOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchCustomers(customerSearch, newOffset); // Fetch next set of customers
        return newOffset;
      });
    }
  };

  const fetchClasses = useCallback(async (newSearch = '', newOffset = 0) => {
    setClassLoading(true);
    try {
      const response = await GetClassListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        classId: prop._id,
      }));

      if (newOffset === 0) {
        setClassesList(data); // Reset the list when new search
      } else {
        setClassesList((prev) => [...prev, ...data]); // Append new data for infinite scroll
      }

      if (data.length < 50) {
        setHasMoreClasses(false); // Stop loading more if fewer than 50 items
      }

      setClassOffset(newOffset);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
    setClassLoading(false);
  }, [businessId]);

  const handleClassScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5;
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreClasses) {
      setClassOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchClasses(classSearch, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };



  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={addproductschema}
            onSubmit={handleSubmit}
          >
            {({  setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={2} pt={2}>
                  <MDBox>
                    <MDBox>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6}>
                        <Autocomplete
                          sx={
                            darkMode
                              ? {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: "white !important",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                    color: "white !important",
                                  },
                                }
                              : {
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                }
                          }
                          value={{ name: productName }}
                          classes={{
                            option: styles.option,
                          }}
                          options={NewProductList}
                          getOptionLabel={(option) => option.name || ""}
                          onFocus={() => {
                            // Always trigger data fetching on focus
                            setProductSearch(''); // Clear any existing search term
                            setProductOffset(0); // Reset the offset
                            setHasMoreProducts(true); // Reset for loading more items
                            fetchProducts('', 0); // Fetch initial data when input is focused
                          }}
                          onInputChange={(event, newInputValue) => {
                            setProductSearch(newInputValue);
                            setProductOffset(0); // Reset the offset
                            setHasMoreProducts(true); // Reset for loading more items
                            fetchProducts(newInputValue, 0); // Fetch new data
                            handleChangeProduct(event, newInputValue);
                          }}
                          onChange={(event, newValue) => {
                            SetProductName(newValue?.name);
                            setFieldValue("productName", newValue); // Update Formik field on selection
                          }}
                          ListboxProps={{
                            onScroll: handleProductScroll,
                            sx: {
                              maxHeight: '200px', // Set a max-height to enable scrolling
                              overflowY: 'auto',  // Enable vertical scrolling
                              fontSize: '0.8125rem',
                              '@media (max-width: 1440px)': {
                                fontSize: '0.775rem',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? "#ffffff" : "#000000" },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {productLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              helperText={touched.productName && errors.productName}
                              error={touched.productName && Boolean(errors.productName)}
                            />
                          )}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Autocomplete
                          sx={
                            darkMode
                              ? {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: "white !important",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                    color: "white !important",
                                  },
                                }
                              : {
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                }
                          }
                          classes={{
                            option: styles.option,
                          }}
                          value={values.customer} // Bind to Formik value
                          options={NewCustomerList}
                          getOptionLabel={(option) => option.name || ''}
                          onFocus={() => {
                            // Always trigger data fetching on focus
                            setCustomerSearch(''); // Clear any existing search term
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers('', 0); // Fetch initial data when input is focused
                          }}
                          onInputChange={(event, newInputValue) => {
                            setCustomerSearch(newInputValue);
                            handleChangeCustomer(event, newInputValue);
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers(newInputValue, 0); // Fetch new data
                          }}
                          onChange={(event, newValue) => {
                            setFieldValue('customer', newValue); // Update Formik field on selection
                          }}
                          ListboxProps={{
                            onScroll: handleScrollCustomer,
                            sx: {
                              maxHeight: '200px', // Set a max-height to enable scrolling
                              overflowY: 'auto', // Enable vertical scrolling
                              fontSize: '0.8125rem',
                              '@media (max-width: 1440px)': {
                                fontSize: '0.775rem',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? '#ffffff' : '#000000' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              helperText={touched.customer && errors.customer}
                              error={touched.customer && Boolean(errors.customer)}
                            />
                          )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses ? (
                          <>
                            <Grid item xs={12} sm={6}>
                            <Autocomplete
                                sx={
                                  darkMode
                                    ? {
                                        "& .MuiInput-underline": {
                                          borderBottom: "2px solid #ffffff",
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-root .MuiInput-input": {
                                          WebkitTextFillColor: "white",
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                          color: "white !important",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                          color: "white !important",
                                        },
                                      }
                                    : {
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                      }
                                }
                                classes={{
                                  option: styles.option,
                                }}
                                value={{ name: className }} // Bind to Formik value
                                options={NewClassesList}
                                getOptionLabel={(option) => option.name || ""}
                                onFocus={() => {
                                  // Always trigger data fetching on focus
                                  setClassSearch(''); // Clear any existing search term
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset hasMore for loading more items
                                  fetchClasses('', 0); // Fetch initial data when input is focused
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setClassSearch(newInputValue);
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset for loading more items
                                  fetchClasses(newInputValue, 0); // Fetch new data
                                  handleChangeClass(event, newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                  setFieldValue("className", newValue); // Update Formik field on selection
                                }}
                                ListboxProps={{
                                  onScroll: handleClassScroll,
                                  sx: {
                                    maxHeight: '200px', // Set a max-height to enable scrolling
                                    overflowY: 'auto',  // Enable vertical scrolling
                                    fontSize: '0.8125rem',
                                    '@media (max-width: 1440px)': {
                                      fontSize: '0.775rem',
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Class *"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: darkMode ? "#ffffff" : "#000000" },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {classLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    helperText={touched.className && errors.className}
                                    error={touched.className && Boolean(errors.className)}
                                  />
                                )}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12} sm={12}>
                            <FormField type="text" label="Description" name="item" />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <FormField type="text" label="Unit" name="unit" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CurrencyFieldText
                            label="Price *"
                            name="price"
                            value={price}
                            error={priceerror}
                            onValueChange={(event) => {
                              setPrice(event.value);
                              setShowerror(true);
                            }}
                          />
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {priceErrorMsg}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <NumberFieldText
                            label="Quantity"
                            name="qty"
                            error={quantityerror}
                            value={quantity}
                            onValueChange={(event) => {
                              setQuantity(event.value);
                              setShowerrorqty(true);
                            }}
                          />
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {quantityErrorMsg}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CurrencyFieldText
                            label="Total Price"
                            name="TotalPrice"
                            disabled
                            value={TotalPrice}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox>
                          <Field
                            as={FormControlLabel}
                            type="checkbox"
                            name="checkbox"
                            control={<Checkbox />}
                            label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</span>}
                            checked={adjustInvoice}
                            onChange={handleChange}
                            sx={{ mt: 1.5, color: darkMode ? "#ffffff" : "#000000" }}
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                    <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="light"
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          // disabled={isDisable || errorButtonDis}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {!productIds ? "Submit" : isDisable ? "Submitting..." : "Submit"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
        <MDBox>
          <Dialog
            open={openAddNewProduct}
            onClose={() => setOpenAddNewProduct(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Add Product
            </DialogTitle>
            <DialogContent>
              <AddNewProductDialog
                addProductSelectType={addProductSelectType}
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={() => setOpenAddNewProduct(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={openAddNewCustomer}
            onClose={() => setOpenAddNewCustomer(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Add Customer
            </DialogTitle>
            <DialogContent>
              <AddCustomerDialog
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={() => setOpenAddNewCustomer(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={openAddNewClass}
            onClose={() => setOpenAddNewClass(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Add Class
            </DialogTitle>
            <DialogContent>
              <AddClassDialog
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={() => setOpenAddNewClass(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={openAddOption}
            onClose={() => setOpenAddOption(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Product/Service information
            </DialogTitle>
            <DialogContent>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Service")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Service")}>
                    <ListItemAvatar>
                      <Avatar alt="Service" src="./components/services.png" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Service"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Services that you provide to customers, for example, landscaping or tax
                            preparation services.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Inventory" src="/static/images/avatar/2.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Inventory"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Products you buy and/or sell and that you track quantities of.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Non Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={() => handleOpenAddNewProduct("Non Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Non Inventory" src="/static/images/avatar/3.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Non Inventory"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Products you buy and/or sell but don’t need to (or can’t) track
                            quantities of, for example, nuts and bolts used in an installation.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>
        </MDBox>
      </MDBox>
    </>
  );
}

export default AddItemDialog;
