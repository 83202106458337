import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from "utils/eventbus";
import { EditCustomerSchema } from "../components/schemas/EditCustomervalidation";
import FormField from "layouts/admin/accountants/components/FormField";

import { useAPICall } from "utils/useapicall";
import { GetCustomerDetails, EditCustomers } from "services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";

function EditCustomer({ onClose, customerId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailId, setEmailId] = useState("");
  const [netTerms, setNetTerms] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [
    {
      isLoading: isEditCustomerLoading,
      isError: isEditCustomerError,
      response: EditCustomerResponse,
    },
    EditCustomerListAPICall,
  ] = useAPICall(EditCustomers, { onLoad: false });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerDetailsAPICall,
  ] = useAPICall(GetCustomerDetails, { onLoad: false });

  useEffect(async () => {
    setLoading(true);
    await CustomerDetailsAPICall({
      payload: [{ customerId: `${customerId}` }],
    });
  }, []);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      setLoading(false);
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      setCustomerName(
        CustomerResponse?.data?.data?.customer?.name !== "---"
          ? CustomerResponse?.data?.data?.customer?.name
          : ""
      );
      setAddress(
        CustomerResponse?.data?.data?.customer?.billingAddress?.line1 !== "---"
          ? CustomerResponse?.data?.data?.customer?.billingAddress?.line1
          : ""
      );
      setEmailId(
        CustomerResponse?.data?.data?.customer?.email !== "---"
          ? CustomerResponse?.data?.data?.customer?.email
          : ""
      );
      setPhone(
        CustomerResponse?.data?.data?.customer?.phone !== "---"
          ? CustomerResponse?.data?.data?.customer?.phone
          : ""
      );
      setNetTerms(CustomerResponse?.data?.data?.customer?.balance);
      setShowDetails(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isEditCustomerLoading) {
      return () => null;
    }
    if (isEditCustomerError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditCustomerResponse && EditCustomerResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
      eventBus.$emit("reloadCustomerDetailsList", EditCustomerResponse);
    }
  }, [EditCustomerResponse]);

  let initialValues = {
    customer: customerName,
    address: address,
    email: emailId,
    phone: phone,
    net: netTerms,
  };

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditCustomerListAPICall({
      payload: [
        {
          name: values.customer,
          phone: phone,
          email: values.email,
          billingAddress: {
            line1: "45612 Main St.",
            line2: null,
            line3: null,
            line4: null,
            line5: null,
            city: "Bayshore",
            state: "CA",
            zip: "94326",
            location: null,
          },
          customerId: `${customerId}`,
        },
      ],
    });
  };

  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={EditCustomerSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Name *" name="customer" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <NumberFormat
                                  customInput={TextField}
                                  variant="standard"
                                  type="tel"
                                  fullWidth
                                  value={phone}
                                  label="Phone Number"
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    mb: 1,
                                    fontSize: `0.823rem !important`,
                                  }}
                                  format="(###) ###-####"
                                  mask=""
                                  onValueChange={(value) => setPhone(value.formattedValue)}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="email" label="Email *" name="email" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Address" name="address" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                {/* <FormField
                                                                type="text"
                                                                label="Net Terms"
                                                                name="net"
                                                            /> */}
                              </Grid>
                              <Grid item xs={12} sm={6}></Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default EditCustomer;
