import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useMaterialUIController } from "context";
import AddNewProductDialog from "layouts/accountants/user/invoice/addnewproduct";
import EditProduct from '../edit-products';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableEditProductDialog = ({ open , productId ,businessId, onClose }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "570px",
                minHeight: "30%",
                backgroundColor: darkMode ? "#424242 !important" : "#ffffff",
                color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle style={{ cursor: 'move' ,justifyContent: "center",display: "flex", color: darkMode ? "#ffffff" : "#7b809a"}} id="draggable-dialog-title">
        Edit Product
        </DialogTitle>
        <DialogContent>
        <EditProduct
                productId={productId}
                businessId={businessId}
                onClose={() => onClose(false)}
              />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DraggableEditProductDialog;