import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddBusinessSchemas } from "./components/schemas/addbusinessschemas.js";
import {
  UpdateClientusersClient,
  GetuserClientDetails,
  EditBusinesss,
} from "services/clientservices.js";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";

function EditBusinessDialog({ onClose, bussinessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [businesName, setBusinessName] = useState("");

  let initialValues = {
    businessname: businesName,
  };

  const [
    {
      isLoading: isAddNewBusinessLoading,
      isError: isAddNewBusinessError,
      response: AddNewBusinessResponse,
    },
    AddNewBusinessListAPICall,
  ] = useAPICall(UpdateClientusersClient, { onLoad: false });

  const [
    {
      isLoading: isFindOneClientLoading,
      isError: isFindOneClientError,
      response: FindOneClientResponse,
    },
    FindOneClientAPICall,
  ] = useAPICall(GetuserClientDetails, { onLoad: false });

  const [
    {
      isLoading: isEditBusinessLoading,
      isError: isEditBusinessError,
      response: EditBusinessResponse,
    },
    EditBusinessAPICall,
  ] = useAPICall(EditBusinesss, { onLoad: false });

  useEffect(async () => {
    setLoading(true);
    await FindOneClientAPICall({
      payload: [{ bussinessId: bussinessId }],
    });
  }, []);

  useEffect(() => {
    if (isFindOneClientLoading) {
      return () => null;
    }
    if (isFindOneClientError) {
      setLoading(false);
      return () => null;
    }
    if (FindOneClientResponse && FindOneClientResponse.data.isSuccess === true) {
      setBusinessName(FindOneClientResponse?.data?.data?.client?.name);
      setLoading(false);
    }
  }, [FindOneClientResponse]);

  useEffect(() => {
    if (isEditBusinessLoading) {
      return () => null;
    }
    if (isEditBusinessError) {
      setErrorMessage(AddNewBusinessResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("EditInventoryUnitSchemaFailed", EditBusinessResponse?.data?.data);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (EditBusinessResponse && EditBusinessResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("EditInventoryUnitSchemaComplete", EditBusinessResponse?.data?.data);
      onClose();
    }
  }, [EditBusinessResponse]);

  useEffect(() => {
    if (isAddNewBusinessLoading) {
      return () => null;
    }
    if (isAddNewBusinessError) {
      setErrorMessage(AddNewBusinessResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (AddNewBusinessResponse && AddNewBusinessResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewInventoryUnitSchemaComplete", AddNewBusinessResponse?.data?.data);
      onClose();
    }
  }, [AddNewBusinessResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditBusinessAPICall({
      payload: [
        {
          name: values?.businessname,
          businessId: bussinessId,
        },
      ],
    });
  };

  return (
    <>
      {!loading ? (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={AddBusinessSchemas}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3} pl={"30%"}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <FormField
                                  type="text"
                                  label="Business Name *"
                                  name="businessname"
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      ) : (
        <MDBox>
          {
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          }
        </MDBox>
      )}
    </>
  );
}

export default EditBusinessDialog;
