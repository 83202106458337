import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
// import { addCategoryschema } from "./components/Itemschema/addCategoryschema";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import FormField from "layouts/admin/accountants/components/FormField";
import {
  AddNewInvenory,
  GetCategoryList,
  GetInventoryUnitList,
  GetProductsList,
  GetRestaurantLocationList,
  GetVendorList,
} from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import * as Yup from "yup";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text";
import { useMaterialUIController } from "context";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
  InputRoot: {
    "&::-webkit-calendar-picker-indicator": {
      display: "none !important",
    },
  },
}));

function AddInventoryDialog({ newProductName, onClose, businessId, restaurantsId }) {
  const [isDisable, setBtnDisable] = useState(false);
  const [unitPrice, setUnitPrice] = useState("");
  const [restaurantId, setRestaurantId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [inventoryUnitList, setInventoryUnitList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [inventoryvendorIds, setInventoryvendorIds] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [adjustInvoice, setAdjustInvoice] = useState(false);
  const [openAddNewinventory, setOpenAddNewinventory] = useState(false);
  const [openAddNewLocation, setOpenAddNewLocation] = useState(false);
  const [editable, seteditable] = useState(false);
  const [addinventory, setAddinventory] = useState(false);
  const [locationNewValue, setLocationNewValue] = useState("");
  const [VendorsNewValue, setVendorsNewValue] = useState("");
  const [unitNewValue, setunitNewValue] = useState("");
  const [currentInventory, setCurrentInventory] = useState("");
  const [itemName, setItemName] = useState(newProductName);
  const [massage, setMassage] = useState("");
  const [vaildError, showVaildError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [inventoryUnitIds, setInventoryUnitIds] = useState("");
  const [price, setPrice] = useState(0);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewInvenory, {
    onLoad: false,
  });
  const [
    {
      isLoading: isInventoryUnitLoading,
      isError: isInventoryUnitError,
      response: InventoryUnitResponse,
    },
    InventoryUnitListAPICall,
  ] = useAPICall(GetInventoryUnitList, { onLoad: false });
  const [
    { isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse },
    VendorListAPICall,
  ] = useAPICall(GetVendorList, { onLoad: false });
  const [
    { isLoading: isLocationLoading, isError: isLocationError, response: LocationResponse },
    LocationListAPICall,
  ] = useAPICall(GetRestaurantLocationList, { onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsList, { onLoad: false });
  const [showSubmit, setShowSubmit] = useState(false);
  const Addvendor = { name: "+ Add Vendor" };
  const NewVendorList = [Addvendor, ...vendorList];
  const AddUnit = { name: "+ Add Unit" };
  const NewUnitList = [AddUnit, ...inventoryUnitList];
  const AddLocation = { name: "+ Add Location" };
  const NewLocationList = [AddLocation, ...locationList];
  const classes = useStyles();

  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      const data = VendorResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          vendorId: prop?._id,
        };
      });
      setVendorList(data);
      setLoadingCircul(false);
    }
  }, [VendorResponse]);

  eventBus.$on("AddnewInventoryUnitSchemaComplete", (data) =>
    AddnewInventoryUnitSchemaComplete(data)
  );
  function AddnewInventoryUnitSchemaComplete(data) {
    setunitNewValue(data?.unit?.name);
    setInventoryUnitIds(data?.unit?._id);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewInventoryVendorComplete", (data) => AddnewInventoryVendorComplete(data));
  function AddnewInventoryVendorComplete(data) {
    setVendorsNewValue(data?.vendor?.name);
    setInventoryvendorIds(data?.vendor?._id);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewLocationComplete", (data) => AddnewLocationComplete(data));
  function AddnewLocationComplete(data) {
    setLocationNewValue(data?.location?.name);
    setLocationId(data?.location?._id);
    setLoadingCircul(false);
  }

  useEffect(async () => {
    setItemName(newProductName);
    await InventoryUnitListAPICall({
      payload: [],
    });
    // await VendorListAPICall({
    //     payload: [{ "businessId": businessId }]
    // })
    await LocationListAPICall({
      payload: [{ businessId: `${restaurantId ? restaurantId : restaurantsId}` }],
    });
    setLoadingCircul(true);
  }, []);

  const initialValues = {
    itemName: itemName,
    inventoryUnit: unitNewValue,
    currentInventory: currentInventory,
    lowStockFlag: "",
    parflag: "",
    unitPrice: unitPrice,
    location: locationNewValue,
    vendors: VendorsNewValue,
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      eventBus.$emit("FailedToAddInventoryItem", isError);
      setBtnDisable(false);
      setShowSubmit(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("IsSuccessToAddInventoryItem", response);
      setBtnDisable(false);
      onClose();
    }
  }, [response]);

  useEffect(() => {
    if (isInventoryUnitLoading) {
      return () => null;
    }
    if (isInventoryUnitError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (InventoryUnitResponse && InventoryUnitResponse.data.isSuccess === true) {
      const data = InventoryUnitResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          InventoryUnitId: prop?._id,
          clientOwnerId: prop?.clientOwnerId,
        };
      });
      setInventoryUnitList(data);
      setLoadingCircul(false);
    }
  }, [InventoryUnitResponse]);

  useEffect(() => {
    if (isLocationLoading) {
      return () => null;
    }
    if (isLocationError) {
      return () => null;
    }
    if (LocationResponse && LocationResponse.data.isSuccess === true) {
      const data = LocationResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          locationId: prop?._id,
        };
      });
      setLocationList(data);
    }
  }, [LocationResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
        };
      });
      setProductsList(data);
    }
  }, [ProductsResponse]);

  const handleSubmit = async (values) => {
    setShowSubmit(true);
    if (Number(values?.lowStockFlag) > Number(values?.parflag)) {
      showVaildError(true);
    }
    await setAPICall({
      payload: [
        {
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          name: values?.itemName,
          location: locationNewValue,
          unitPrice: Number(price),
          unit: unitNewValue,
          currentInventory: Number(values?.currentInventory),
          lowStockFlag: Number(values?.lowStockFlag),
          parFlag: Number(values?.parflag),
          vendor: values?.vendors,
        },
      ],
    });
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleVendorsValueChange = (event, newValue) => {
    setVendorsNewValue(newValue);
    const found = vendorList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setInventoryvendorIds(found?.vendorId);
    }
  };
  const handleInventoryValueChange = (event, newInputValue) => {
    setunitNewValue(newInputValue);
    const found = inventoryUnitList.find((obj) => {
      return obj.name === newInputValue;
    });
    if (found) {
      setInventoryUnitIds(found?.InventoryUnitId);
    }
  };

  const handleLocationValueChange = (event, newValue) => {
    setLocationNewValue(newValue);
    const foundId = locationList.find((obj) => {
      return obj.name === newValue;
    });
    if (foundId) {
      setLocationId(foundId?.locationId);
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              itemName: Yup.string()
                .trim()
                .required("Item  name is required.")
                .min(3, "Item name should be more than 3 characters."),
              location: !locationNewValue
                ? Yup.string().nullable().required("Select Location")
                : "",
              inventoryUnit: !unitNewValue
                ? Yup.string().nullable().required("Select Inventory Unit")
                : "",
              currentInventory: Yup.string("Current Inventory must be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Current Inventory must be a number type"
                )
                .nullable()
                .required("Current Inventory is required"),
              lowStockFlag: Yup.string("Low Stock Flag must be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Low Stock Flag must be a number type"
                )
                .nullable()
                .required("Low Stock Flag is required"),
              parflag: Yup.string("Par Flag be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Par Flag must be a number type"
                )
                .nullable()
                .required("Par Flag is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox
                  sx={{
                    "@media (max-width: 1440px)": {
                      padding: "0px 16px 14px 16px",
                    },
                    padding: "16px 16px 20px 16px",
                  }}
                >
                  <MDBox>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "5px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Item Name *"
                          name="itemName"
                          disabled={editable}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "-4px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.7,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.7,
                          },
                        }}
                      >
                        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                          <Autocomplete
                            sx={
                              darkMode
                                ? {
                                    "& .MuiInput-underline.Mui-disabled:before": {
                                      borderBottom: "1px solid #949494",
                                    },
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: darkMode ? "white" : "black",
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: darkMode ? "white" : "black",
                                    },
                                    "& .MuiFormLabel-root": {
                                      color: darkMode ? "white !important" : "black",
                                    },
                                    "& .MuiAutocomplete-popupIndicator": {
                                      color: darkMode ? "white !important" : "black !important",
                                    },
                                    "& .MuiAutocomplete-clearIndicator": {
                                      color: darkMode ? "white !important" : "black !important",
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: darkMode ? "white" : "black", // Change this line
                                    },
                                  }
                                : {}
                            }
                            noOptionsText={
                              <MDTypography sx={{ fontSize: "0.775rem", cursor: "pointer" }}>
                                {locationNewValue} (New Location)
                              </MDTypography>
                            }
                            disablePortal
                            name="location"
                            id="combo-box-demo"
                            ListboxProps={{
                              style: { maxHeight: "250px" },
                              position: "bottom-start",
                            }}
                            value={{ name: locationNewValue }}
                            options={locationList}
                            getOptionLabel={(option) => option.name}
                            disabled={editable}
                            onInputChange={(event, newInputValue) => {
                              handleLocationValueChange(event, newInputValue);
                            }}
                            // PaperComponent={(props) => (
                            //     <Paper {...props} sx={{ backgroundColor: darkMode ? '#1a2035' : '#ffffff' }} />
                            // )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Location *"
                                name="location"
                                // InputProps={!editable ? { disableUnderline: true } : ''}
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: darkMode ? "#ffffff" : "#000000" },
                                }}
                                sx={{
                                  "& .MuiFormLabel-root": {
                                    marginTop: "-3px",
                                    color: darkMode ? "#ffffff" : "#7b809a !important",
                                  },
                                }}
                              />
                            )}
                          />
                          {!locationNewValue && (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="location" />
                              </MDTypography>
                            </MDBox>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "12px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.4,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.4,
                          },
                        }}
                      >
                        <CurrencyFieldText
                          label="Unit Price *"
                          name="unitPrice"
                          value={price}
                          fullWidth
                          onValueChange={(event) => {
                            setPrice(event.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "-26px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.6,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.6,
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Current Inventory *"
                          name="currentInventory"
                          disabled={editable}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{
                            "@media (max-width: 440px)": {
                              marginTop: "-24px",
                            },
                            "@media (max-width: 1920px)": {
                              marginTop: "13px !important",
                            },
                            "@media (max-width: 1440px)": {
                              marginTop: "13px !important",
                            },
                          }}
                        >
                          <Autocomplete
                            sx={
                              darkMode
                                ? {
                                    "& .MuiInput-underline.Mui-disabled:before": {
                                      borderBottom: "1px solid #949494",
                                    },
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: darkMode ? "white" : "black",
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: darkMode ? "white" : "black",
                                    },
                                    "& .MuiFormLabel-root": {
                                      color: darkMode ? "white !important" : "black",
                                    },
                                    "& .MuiAutocomplete-popupIndicator": {
                                      color: darkMode ? "white !important" : "black !important",
                                    },
                                    "& .MuiAutocomplete-clearIndicator": {
                                      color: darkMode ? "white !important" : "black !important",
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: darkMode ? "white" : "black", // Change this line
                                    },
                                  }
                                : {}
                            }
                            noOptionsText={
                              <MDTypography sx={{ fontSize: "0.775rem", cursor: "pointer" }}>
                                {unitNewValue} (New Unit)
                              </MDTypography>
                            }
                            name="inventoryUnit"
                            disablePortal
                            id="combo-box-demo"
                            ListboxProps={{
                              style: { maxHeight: "150px" },
                              position: "bottom-start",
                            }}
                            value={{ name: unitNewValue }}
                            options={inventoryUnitList}
                            getOptionLabel={(option) => option.name}
                            disabled={editable}
                            onInputChange={(event, newInputValue) => {
                              handleInventoryValueChange(event, newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Inventory Unit *"
                                name="inventoryUnit"
                                variant="standard"
                                sx={{
                                  "& .MuiFormLabel-root": {
                                    marginTop: "-3px",
                                    color: darkMode ? "#ffffff" : "#7b809a !important",
                                  },
                                }}
                              />
                            )}
                          />
                          {!unitNewValue && (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="inventoryUnit" />
                              </MDTypography>
                            </MDBox>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "24px",
                            marginTop: "16px",
                          },
                        }}
                      >
                        <FormField type="text" label="Vendor" name="vendors" disabled={editable} />
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                                                <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                                                    <Autocomplete
                                                        sx={{
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "black",
                                                            },
                                                        }}
                                                        noOptionsText={<MDTypography
                                                            sx={{ fontSize: "0.775rem", cursor: 'pointer' }}
                                                        >
                                                            {VendorsNewValue} (New Vendor)
                                                        </MDTypography>}
                                                        name="vendors"
                                                        value={{ name: VendorsNewValue }}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        ListboxProps={{ style: { maxHeight: "150px" }, position: "bottom-start" }}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleVendorsValueChange(event, newInputValue);
                                                        }}
                                                        options={vendorList}
                                                        getOptionLabel={(option) => option.name}
                                                        disabled={editable}
                                                        // onChange={(event, newValue) => {
                                                        //     setVendorsNewValue(newValue);
                                                        // }}
                                                        renderInput={(params) =>
                                                            <TextField {
                                                                ...params
                                                            }
                                                                label="Vendor"
                                                                name="vendors"
                                                                variant="standard"
                                                                sx={{
                                                                    "& .MuiFormLabel-root": {
                                                                        marginTop: '5px',
                                                                        color: '#7b809a !important'
                                                                    }
                                                                }
                                                                }
                                                            />}
                                                    />
                                                    {!VendorsNewValue && <MDBox mt={0.75}>
                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                            <ErrorMessage name="vendors" />
                                                        </MDTypography>
                                                    </MDBox>}
                                                </FormControl>
                                            </Grid> */}
                    </Grid>
                    <Grid container spacing={3} sx={{ mb: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginTop: "-20px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Low Stock Flag *"
                          name="lowStockFlag"
                          disabled={editable}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginTop: "-20px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Par Flag *"
                          name="parflag"
                          disabled={editable}
                        />
                        <>
                          {values?.parflag &&
                          Number(values?.lowStockFlag) > Number(values?.parflag) ? (
                            <MDBox mt={-0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                Par Flag must be Greater than Low Stock
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              ></MDTypography>
                            </MDBox>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox
                    mb={-2}
                    sx={{
                      "@media (max-width: 1440px)": {
                        marginTop: "16px",
                      },
                      marginTop: "40px",
                    }}
                    display="flex"
                    justifyContent="end"
                  >
                    {editable === true && (
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="info"
                        onClick={() => setAddinventory(true)}
                      >
                        Add Inventory
                      </MDButton>
                    )}
                    {editable === false && (
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={() => seteditable(false)}
                        >
                          {showSubmit === true ? "Adding..." : "Add"}
                        </MDButton>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
      {/* <MDBox>
                <Dialog
                    // fullScreen
                    open={openAddNewLocation}
                    onClose={() => setOpenAddNewLocation(false)}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "570px",
                            },
                        },
                    }}
                >
                    <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Location</DialogTitle>
                    <DialogContent>
                        <AddInventoryLocation restaurantsId={restaurantId ? restaurantId : restaurantsId} onClose={() => setOpenAddNewLocation(false)} />
                    </DialogContent>
                </Dialog>
            </MDBox> 
             <MDBox>
                <Dialog
                    // fullScreen
                    open={addinventory}
                    onClose={() => setAddinventory(false)}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "570px",
                            },
                        },
                    }}
                >
                    <DialogContent>
                        <AddCurrontInvetory onCloseAdd={() => setAddinventory(false)} />
                    </DialogContent>
                </Dialog>
            </MDBox>
            <MDBox>
                <Dialog
                    open={openEditVendor}
                    onClose={() => setOpenEditVendor(false)}
                    aria-labelledby="alert-dialog-title"
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "570px",
                                minHeight: "30%"
                            },
                        },
                    }}
                >   <DialogTitle sx={{
                    justifyContent: "center",
                    display: 'flex'
                }}>Add Vendor</DialogTitle>
                    <DialogContent>
                        <AddVendorDialog businessId={businessId} restaurantsId={restaurantId ? restaurantId : restaurantsId} onClose={() => setOpenEditVendor(false)}
                        />
                    </DialogContent>
                </Dialog>
            </MDBox>
            <MDBox>
                <Dialog
                    open={openAddNewinventory}
                    onClose={() => setOpenAddNewinventory(false)}
                    aria-labelledby="alert-dialog-title"
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "570px",
                            },
                        },
                    }}
                >
                    <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Inventory Unit</DialogTitle>
                    <DialogContent  >
                        <AddNewInventoryUnitDialog invoiceId={invoiceId} restaurantsId={restaurantId ? restaurantId : restaurantsId} onClose={() => setOpenAddNewinventory(false)}
                        />
                    </DialogContent>
                </Dialog>
            </MDBox> */}
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content={massage}
          // autoHideDuration={3000}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={massage}
          // autoHideDuration={3000}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </>
  );
}

export default AddInventoryDialog;
