import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import { AddNewSchema } from "layouts/clients/components/schemas/addnewvalidations";
import initialValues from "layouts/clients/components/schemas/initialValues";
import FormField from "layouts/admin/accountants/components/FormField";

import { SITE_URL } from "helpers/config";
import { Link, useNavigate } from "react-router-dom";
import { useAPICall } from "../../../utils/useapicall";
import { AddNewUserClient, checkEmail, AccountantClientEdit } from "../../../services/userservices";
import MDSnackbar from "components/MDSnackbar";
import UserHoc from "hoc/userHoc";
import { eventBus } from "utils/eventbus";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

function AddNewClient() {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewUserClient, {
    onLoad: false,
  });
  const [{ response: checkEmResponse }, checkEmailAPICall] = useAPICall(checkEmail, {
    onLoad: false,
  });
  const [
    { isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },
    updateClientAPICall,
  ] = useAPICall(AccountantClientEdit, { onLoad: false });

  const Confirm = async () => {
    await updateClientAPICall({
      payload: [
        {
          isBlocked: false,
          isArchived: false,
          bussinessId: `${userId}`,
        },
      ],
    });
    navigate("/accountant/client/list");
    setIsOpen(false);
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      switch (response?.code) {
        case "DATA401":
          navigate("/user/logout");
        case "EMAIL422":
          await checkClientEmail();
        default: {
          console.log("something else");
        }
      }
      if (response?.data?.message && response?.code === "EMAIL422") {
        setErrorMessage(response.data.message);
      }
      setDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      navigate("/accountant/client/list");
    }
  }, [response]);

  useEffect(() => {
    if (checkEmResponse && checkEmResponse?.data?.isSuccess === true) {
      // eventBus.$emit("openAddDeletedClientPopup", checkEmResponse);
      if (checkEmResponse.data.data) {
        setUserId(checkEmResponse?.data?.data?.role?.client?._id);
        setUserType(checkEmResponse.data.data.userType);
        setStatus(checkEmResponse.data.data.status);
        setIsOpen(
          checkEmResponse.data.data.status === "ARCHIVED" ||
            checkEmResponse.data.data.status === "BLOCKED"
            ? true
            : false
        );
        setShowErrorSnackbar(checkEmResponse.data.data.status === "ACTIVE" ? true : false);
        setName(checkEmResponse.data.data.role.client.name);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
      }
    }
  }, [checkEmResponse]);

  const handleSubmit = async (values) => {
    setClientEmail(values.email);
    await setAPICall({
      payload: [
        {
          companyName: `${values.businessname}`,
          email: `${values.email}`,
          phone: phone,
          fname: `${values.firstname}`,
          lname: `${values.lastname}`,
          returnLink: `${SITE_URL}/user/create-password`,
        },
      ],
    });

    setDisable(true);
  };

  const checkClientEmail = async () => {
    await checkEmailAPICall({
      payload: [
        {
          email: `${clientEmail}`,
        },
      ],
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={AddNewSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                        <MDBox lineHeight={0}>
                          <MDTypography variant="h5">Add New Client</MDTypography>
                        </MDBox>
                        <MDBox mt={1.625}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormField type="text" label="Client Name *" name="businessname" />
                            </Grid>
                          </Grid>
                          <MDBox lineHeight={0} mb={1} mt={1}>
                            <MDTypography variant="h6" textTransform="capitalize">
                              Client Info
                            </MDTypography>
                          </MDBox>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="First Name *" name="firstname" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Last Name *" name="lastname" />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <FormField type="email" label="Email *" name="email" />
                          </Grid>
                          <Grid item xs={12}>
                            <NumberFormat
                              customInput={TextField}
                              variant="standard"
                              type="tel"
                              fullWidth
                              value={phone}
                              label="Phone Number"
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                                mb: 1,
                                fontSize: `0.823rem !important`,
                              }}
                              format="(###) ###-####"
                              mask=""
                              onValueChange={(value) => setPhone(value.formattedValue)}
                            />
                          </Grid>
                        </MDBox>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                          <MDButton
                            disabled={disable}
                            variant="gradient"
                            color="light"
                            component={Link}
                            to="/accountant/client/list"
                          >
                            Cancel
                          </MDButton>
                          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                            <MDButton
                              disabled={disable}
                              type="submit"
                              variant="gradient"
                              color="info"
                            >
                              {disable ? "creating..." : "create"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="alert-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "35%",
                maxWidth: "500px",
              },
            },
          }}
        >
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "0.60rem",
              }}
            >
              {status === "BLOCKED" && (
                <h1>
                  {name} using the email {clientEmail} already exists and is deactivated.
                </h1>
              )}
              {status === "ARCHIVED" && (
                <h1>
                  {name} using the email address {clientEmail} was deleted.
                </h1>
              )}
            </DialogContentText>
            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ fontWeight: 200, fontSize: "1.20rem", textAlign: "center" }}
              >
                {status === "ARCHIVED" && "Would you like to restore this account?"}
                {status === "BLOCKED" && "Would you like to re-activate this account?"}
              </MDTypography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <MDButton variant="gradient" color="success" onClick={Confirm}>
              Yes
            </MDButton>
            <MDButton variant="gradient" color="error" onClick={() => setIsOpen(false)}>
              No
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content="Client Created Successfully!"
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={ErrorMessage}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(AddNewClient);
