import {
    Box,
    Pagination, PaginationItem,
    ThemeProvider,
    createMuiTheme,
} from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from '@mui/material/Checkbox';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useAsyncDebounce, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { eventBus } from 'utils/eventbus';
import CustomUncheckedIcon from './CustomUnchecked';
import DataTableBodyCell from "./Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "./Tables/DataTable/DataTableHeadCell";
import { useMaterialUIController } from "context";


const theme = createMuiTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    maxWidth: '20%',
                },
            },
        },
    },
    props: {
        MuiCheckbox: {
            icon: <CustomUncheckedIcon />,
        },
    },
    overrides: {
        MuiCheckbox: {
            root: {
                '&.Mui-disabled': {
                    pointerEvents: 'auto',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    cursor: 'not-allowed',
                    // This syntax is necessary (instead of just ".MuiSvgIcon-root") because of the nested theme causing the global class names to be suffixed)
                    '& [class*=MuiSvgIcon-root] .innerBox': {
                        fill: '#eee',
                    },
                },
            },
        },
    },
});
function DataTable({
    entriesPerPage,
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    response,
    selectTab,
    showCheckBoxs,
    showCheck,
    footer,
    ClientHeigth
}) {
    const limit = localStorage.getItem("PerPagelimit")
    const defaultValue = limit ? limit : 10;
    const [newpageIndex, setNewpageIndex] = React.useState(0);
    const [selectAllButton, setSelectAllButton] = useState(false);
    const [selectWidth, setSelectwidh] = useState(75);
    const [selectTotalShow, setSelectTotalShow] = useState(defaultValue);
    const [showFooter , setShoweFooter] = useState(true);
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries.map((el) => el.toString())
        : ["10", "20", "50", "100"];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    let pageOptions1 = [];
    let pageOptionsFirst = [];

    useEffect(()=>{
        console.log("footer",footer)
        if(footer == false){
            setShoweFooter(false)
        }
    },[footer])

    useEffect(() => {
        setSelectTotalShow(10);
        const ThisOffsethandle = localStorage.getItem("offset")
        if (newpageIndex === 0) {
            setNewpageIndex(0)
            localStorage.setItem("offset", 0)
            localStorage.setItem("newpageIndex", 0)
        } else {
            const num = ThisOffsethandle.toString();
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            // localStorage.setItem("newpageIndex", Number(ThisOffsethandle == 0 ? 0 : result))
            setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result))
        }
    }, [])

    eventBus.$on('showSelectTabs', (data) => showSelectTabs(data));
    function showSelectTabs(data) {
        setNewpageIndex(0);
    }
    const totalRecords = response
    for (let i = 0; i < totalRecords;) {
        i = i + Number(selectTotalShow)
        pageOptionsFirst.push(i);
    }
    pageOptionsFirst.map((prop, key) => {
        const num = prop.toString();
        if (num >= 100) {
            const str = num.substring(0, num.length - 1);
            pageOptions1.push(str - 1)
        } else {
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            pageOptions1.push(result - 1)
        }
    })

    eventBus.$on('MoveMenuResponseComplete', (data) => MoveMenuResponseComplete(data));
    function MoveMenuResponseComplete(data) {
        const finaldata = (Number(data) - 10).toString()
        const str = finaldata.split('').reverse().join('')
        const result = str.replace(/^0+/, '');
        setNewpageIndex(Number(result));
    }

    eventBus.$on('showInvoiceStatus', (data) => showInvoiceStatus(data));
    function showInvoiceStatus(data) {
        const found1 = data?.find(obj => {
            return obj.ocrStatus === 'INPROGRESS';
        });
        if (found1) {
            setSelectAllButton(true);
        } else {
            setSelectAllButton(false);
        }
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ des, indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef
            const checkDes = des === 'In Progress' || des === 'Synced to Quickbooks' ? true : false;

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])
            return (
                <ThemeProvider theme={theme}>
                    <MDBox display="flex" alignItems="center">
                        <Checkbox ref={resolvedRef} disabled={checkDes} {...rest} />
                        <MDBox ml={1}>
                            <MDTypography variant="caption" fontWeight="medium" color="text">
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </ThemeProvider>
            )
        }
    )
    const ALLselectTab = true;
    const tableInstance = useTable(
        { columns, data, selectTab, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        onPageChange = (eventBus.$emit('ChangePaginationPage', pageIndex)),
    } = tableInstance;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    // const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        var tempId = [];
        tempId = selectedFlatRows.map(
            d => d.original.id
        )
        // setSelectedRows(tempId);

        eventBus.$emit('invoiceRowSelected', { selectedRowIds: tempId });
    }, [selectedRowIds]);


    const setEntriesPerPage = (value) => setPageSize(value);

    // Search input value state
    const [search, setSearch] = useState(globalFilter);
    const [pages, setPages] = useState(1);
    const ThisOffsetuse = localStorage.getItem("offset");
    const finalPage = ThisOffsetuse == 0 ? 1 : pages;
    const isFirstPage = finalPage === 1;
    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions1.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }


    const handleChange = (e, p) => {
        setPages(p);
        const dataStr = (p - 1).toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        eventBus.$emit('onclickchangePages', Number(p - 1)) || setNewpageIndex(Number(p - 1))
    };

    const changePerPageEntries = (value) => {

        setNewpageIndex(0)
        setEntriesPerPage(parseInt(value, 10));
        setSelectTotalShow(value);
        eventBus.$emit('onclickchangeTotalPerPage', Number(value))
    };

    useEffect(()=>{

    })

    return (
        <TableContainer sx={{ boxShadow: "none" ,backgroundColor: darkMode ? '#202940' : '#ffffff',height:'67vh'    }} >
            {/* <EnhancedTableToolbar numSelected={SelectedRows.length} /> */}
            {/* {entriesPerPage || canSearch ? (
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {canSearch && (
                        <MDBox width="12rem" ml="auto">
                            <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    )}
                </MDBox>
            ) : null} */}
            <Table {...getTableProps()} >
                <MDBox component="thead" >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}
                        style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: darkMode ? "#202940" : "#ffffff",
                            zIndex: 100,
                          }}
                        >
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? column.width : "auto"}
                                    align={showCheckBoxs === false ? "left" : "center"}
                                    // ml={showCheck == false ? -60 : 0}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}

                        </TableRow>
                    ))}
                </MDBox>
                <TableBody  {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow  {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <DataTableBodyCell
                                        showCheckBoxs={showCheckBoxs}
                                        ClientHeigth={ClientHeigth}
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={showCheckBoxs === false ? "left" : "center"}
                                        ml={-60}
                                        selectHeight={selectTab}
                                        // width={cell.column.Header === 'Comment' ? "30%" : "max-content"}
                                        // display={cell.column.Header === 'Comment' ? "block" : 'inline-block'}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {
                response == 0 && <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "15%", marginBottom: "5%" }}
                >
                    No records found
                </MDTypography>
            }
            {showFooter == true ? <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={!showTotalEntries && pageOptions1.length === 1 ? 0 : 3}
                right={25}
                position="fixed"  // Use "fixed" positioning
                bottom={20}        // Stick to the bottom of the viewport
                zIndex={1000}     // Ensure it's above other content if necessary
                bgcolor= {darkMode ? "#202940" :"background.paper"}
                width="91.2%"
                borderTop="1px solid #ddd"
                height="48px"
            >
                 <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}  mb={4}>
                    {response >= 1 && (
                        <MDBox display="flex" alignItems="center" mt={2} mr={1.2}>
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                    changePerPageEntries(newValue);
                                }}
                                size="small"
                                sx={darkMode ? { width: "5rem" , background:"white", borderRadius:"8px" } :{ width: "5rem"}}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                            <MDTypography variant="caption" color={darkMode ? "light" :"secondary"}>
                                &nbsp;&nbsp;entries
                            </MDTypography>
                        </MDBox>
                    )}
                    {pageOptions1.length > 1 && (
                        <MDPagination
                            variant={pagination.variant ? pagination.variant : "gradient"}
                            color={pagination.color ? pagination.color : "info"}
                        >
                            {
                                <Pagination
                                page={finalPage}
                                color="primary"
                                sx={{ justifyContent: "center", marginTop: 2, mr: 1.2 }}
                                onChange={handleChange}
                                count={pageOptionsFirst.length}
                                renderItem={(item) => (
                                  <>
                                    {item.type === 'previous' && isFirstPage ? null : (
                                      <PaginationItem
                                        {...item}
                                        sx={{
                                          color: "#237dea",
                                          "&.Mui-selected": {
                                            backgroundColor: "#237dea",
                                            color: "white",
                                          },
                                        }}
                                        components={{
                                          next: () => <>{item.type === 'next' && 'Next'}</>,
                                          previous: () => <>{item.type === 'previous' && 'Previous'}</>,
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              />
                            }
                        </MDPagination>
                    )}
                    {response >= 1 &&
                        <MDBox sx={{ mr: 1.2, mb: -1.5 }}>
                            <MDTypography variant="button" color={darkMode ? "light" :"secondary"} fontWeight="regular">
                                Showing {(newpageIndex) == 0 ? '1' : (finalPage * Number(selectTotalShow)) - Number(selectTotalShow)} to {response < finalPage * Number(selectTotalShow) ? response : finalPage * Number(selectTotalShow)} of {response} entries
                            </MDTypography>
                        </MDBox>}
                    {/* {response >= 1 &&
                        <MDBox sx={{ mr: 1.2, mb: -1.5 }}>
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                                Showing {(pages - 1) == 0 ? '01' : `${(pages - 1)}1`} to {response < Number(`${pages - 1}0`) + Number(selectTotalShow) ? response : Number(`${pages - 1}0`) + Number(selectTotalShow)} of {response} entries
                            </MDTypography>
                        </MDBox>} */}
                </MDBox>

            </Box>:''}
        </TableContainer >
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
