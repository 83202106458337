import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import FormField from "layouts/clients/components/FormField";
import { AddNewSchema } from "layouts/clients/components/schemas/editclient";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import {
  AccountantClientEdit,
  GetRolescope,
  GetuserClientDetails,
} from "../../../services/userservices";
import { useAPICall } from "../../../utils/useapicall";
import AddOwnerDetailsDialog from "../add-owner";

function EditClients({ businessId }) {
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [bussinessId, setBusinessId] = useState(businessId);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [controller] = useMaterialUIController();
  const [syncPercent, setSyncPrcenet] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [isDisable1, setIsDisable1] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [isOwnerAsigned, setIsOwnerAsigned] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAddOwner, setOpenAddOwner] = useState(false);
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AccountantClientEdit, {
    onLoad: false,
  });
  const [
    { isLoading: isRoleLoading, isError: isRoleError, response: roleResponse },
    getRoleAPICall,
  ] = useAPICall(GetRolescope, { onLoad: false });
  const [
    {
      isLoading: isGetDetailsLoading,
      isError: isGetDetailsError,
      response: clientDetailsDataResponse,
    },
    getClientDetailsAPICall,
  ] = useAPICall(GetuserClientDetails, { onLoad: false });

  useEffect(() => {
    eventBus.$emit("OnSetBlur", !showForm);
    eventBus.$emit("OnSetBlurEdit", !showForm);
    // loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({})
  }, [showForm]);

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    clientname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: "",
  };

  useEffect(async () => {
    setBusinessId(businessId);
    await getRoleAPICall({
      payload: [],
    });
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${bussinessId}` }],
    });
  }, []);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      setIsSubmitDisable(false);
      setShowErrorSnackbar(true);
      setIsDisable(false);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setIsSubmitDisable(false);
      setShowSnackbar(true);
      setIsDisable(false);
      setIsDisable1(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  }, [response]);

  useEffect(() => {
    if (isGetDetailsLoading) {
      return () => null;
    }

    if (isGetDetailsError) {
      setLoading(false);
      setIsDisable1(false);
      return () => null;
    }

    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      const adminresult = clientDetailsDataResponse?.data?.data?.client;
      setIsOwnerAsigned(adminresult?.ownerAssigned);
      setprofileEmail(adminresult?.owner?.email);
      setprofileFname(adminresult?.owner?.fname);
      setprofileLname(adminresult?.owner?.lname);
      setprofileBusinessName(adminresult?.name);
      setprofileNumber(adminresult?.owner?.phone);
      setPhone(adminresult?.owner?.phone);
      setActiveUser(adminresult?.isBlocked);
      setSyncPrcenet(adminresult?.syncPercent);
      adminresult?.documentSource === "Paynthr"
        ? setIsDisable(false)
        : adminresult?.syncPercent === 100
        ? setIsDisable(false)
        : setIsDisable(true);
      setShowForm(true);
      setLoading(false);
      setIsDisable1(false);
    }
  }, [clientDetailsDataResponse]);

  useEffect(() => {
    if (isRoleLoading) {
      return () => null;
    }

    if (isRoleError) {
      return () => null;
    }

    if (roleResponse && roleResponse.data.isSuccess === true) {
      const adminresult = roleResponse.data.data.records.find(
        ({ template }) => template === "admin"
      );
      const clientresult = roleResponse.data.data.records.find(
        ({ template }) => template === "executive"
      );
    }
  }, [roleResponse]);

  const handleSubmit = async (values) => {
    setIsDisable1(true);
    setIsSubmitDisable(true);
    setIsDisable(true);
    await setAPICall({
      payload: [
        {
          companyName: `${values.clientname}`,
          phone: phone,
          fname: `${values.firstname}`,
          lname: `${values.lastname}`,
          bussinessId: `${bussinessId}`,
          isBlocked: activeUser,
        },
      ],
    });
  };

  const ToggleChange = async () => {
    setActiveUser(!activeUser);
  };

  eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
  function successInAddOwner(data) {
    setOpenAddOwner(false);
    setLoading(true);
    getRoleAPICall({
      payload: [],
    });
    getClientDetailsAPICall({
      payload: [{ bussinessId: `${bussinessId}` }],
    });
  }

  return (
    <>
      {!showForm ? (
        <MDBox display="flex" justifyContent="center">
          {<Loader />}
        </MDBox>
      ) : showForm && isOwnerAsigned ? (
        <>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={AddNewSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <MDBox lineHeight={0}>
                    <MDTypography variant="h5">Edit Client</MDTypography>
                  </MDBox>
                  <MDBox mt={1.625}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField type="text" label="First Name *" name="firstname" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField type="text" label="Last Name *" name="lastname" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormField type="email" label="Email *" name="email" disabled />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField type="text" label="Client Name *" name="clientname" />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberFormat
                        customInput={TextField}
                        variant="standard"
                        type="tel"
                        fullWidth
                        value={phone}
                        label="Phone Number"
                        InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }} // Change label color to red
                        InputProps={{ style: darkMode ? { color: "#ffffff" } : {} }} // Change input text color to blue
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                          },
                          color: "black",
                          mb: 1,
                          mt: -0.5,
                          fontSize: `0.823rem !important`,
                        }}
                        format="(###) ###-####"
                        mask=""
                        onValueChange={(value) => setPhone(value.formattedValue)}
                      />
                    </Grid>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="left">
                    <Grid item xs={12} sm={3} sx={{ pr: 11 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!activeUser}
                            onChange={ToggleChange}
                            disabled={isDisable}
                          />
                        }
                        label={<span style={darkMode ? { color: "#ffffff" } : {}}>Is Active</span>}
                      />
                    </Grid>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                    <MDButton
                      disabled={isDisable1}
                      variant="gradient"
                      color="light"
                      component={Link}
                      to="/accountant/client/list"
                    >
                      Cancel
                    </MDButton>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                      <MDButton disabled={isDisable} type="submit" variant="gradient" color="info">
                        {isSubmitDisable ? "Updating..." : "update"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              content="Client Updated Successfully!"
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={ErrorMessage}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      ) : (
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={AddNewSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox lineHeight={0}>
                  <MDTypography variant="h5">Edit Client</MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField type="text" label="Client Name *" name="clientname" disabled />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={6} display="flex" justifyContent="center">
                  <MDTypography color={darkMode ? "light" : "secondary"}>
                    Add client’s email that you want to have ownership over this account.
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="center">
                  <MDTypography color={darkMode ? "light" : "secondary"}>
                    They will be able to upload and review documents.
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} display="flex" justifyContent="center">
                  <MDButton variant="gradient" color="info" onClick={() => setOpenAddOwner(true)}>
                    {" "}
                    Add Client Email
                  </MDButton>
                </MDBox>
                <MDBox mt={15} width="100%" display="flex" justifyContent="end">
                  <MDButton
                    disabled={isDisable1}
                    variant="gradient"
                    color="light"
                    component={Link}
                    to="/accountant/client/list"
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
      <MDBox>
        <Dialog
          open={openAddOwner}
          onClose={() => setOpenAddOwner(false)}
          aria-labelledby="alert-dialog-title"
          sx={
            darkMode
              ? {
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      backgroundColor: "#424242 !important",
                      color: "white !important",
                    },
                  },
                }
              : {
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                    },
                  },
                }
          }
        >
          {" "}
          <DialogTitle
            sx={
              darkMode
                ? {
                    justifyContent: "center",
                    display: "flex",
                    color: "#ffffff",
                  }
                : {
                    justifyContent: "center",
                    display: "flex",
                  }
            }
          >
            Add Owner For - {profileBuinessName}
          </DialogTitle>
          <DialogContent>
            <AddOwnerDetailsDialog businessId={businessId} />
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDBox display="flex" justifyContent="center">
        {loading && <Loader />}
      </MDBox>
    </>
  );
}

export default UserHoc(EditClients);
