import * as React from "react";
import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useRowSelect,
} from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import { eventBus } from "utils/eventbus";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import Checkbox from "@mui/material/Checkbox";
import CustomUncheckedIcon from "./CustomUnchecked";
import DataTableHeadCell from "layouts/accountants/user/invoice/components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "layouts/accountants/user/invoice/components/Tables/DataTable/DataTableBodyCell";
import { Box, createMuiTheme, ThemeProvider } from "@mui/material";
import { Pagination, PaginationItem } from "@mui/material";

const theme = createMuiTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: "20%",
        },
      },
    },
  },
  props: {
    MuiCheckbox: {
      icon: <CustomUncheckedIcon />,
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        "&.Mui-disabled": {
          pointerEvents: "auto",
          "&:hover": {
            backgroundColor: "transparent",
          },
          cursor: "not-allowed",
          // This syntax is necessary (instead of just ".MuiSvgIcon-root") because of the nested theme causing the global class names to be suffixed)
          "& [class*=MuiSvgIcon-root] .innerBox": {
            fill: "#eee",
          },
        },
      },
    },
  },
});

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  response,
  selectTab,
  showCheckBoxs,
  showCheck,
  ClientHeigth,
}) {
  const limit = localStorage.getItem("limit");
  const defaultValue = limit ? limit : 10;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [newpageIndex, setNewpageIndex] = React.useState(0);
  const [selectAllButton, setSelectAllButton] = useState(false);
  const [selectTotalId, setSelectTotalId] = useState([]);
  const [selectWidth, setSelectwidh] = useState(75);
  const [retrySuccess, setRetrySuccess] = useState(false);
  const [selectTotalShow, setSelectTotalShow] = useState(defaultValue);
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "20", "50", "100"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  let pageOptions1 = [];
  let pageOptionsFirst = [];
  let fintotalRecords;
  if (selectTab === "Classify") {
    fintotalRecords = response?.data?.data?.countClassify;
  } else if (selectTab === "AskClient") {
    fintotalRecords = response?.data?.data?.countAskClient;
  } else if (selectTab === "ToReview") {
    fintotalRecords = response?.data?.data?.countToReview;
  } else if (selectTab === "PendingApproval") {
    fintotalRecords = response?.data?.data?.countPendingApproval;
  } else if (selectTab === "Approved") {
    fintotalRecords = response?.data?.data?.countApproved;
  } else if (selectTab === "Excluded") {
    fintotalRecords = response?.data?.data?.countExcluded;
  } else if (selectTab === "") {
    fintotalRecords = response?.data?.data?.countAll;
  }

  useEffect(() => {
    const ThisOffsethandle = localStorage.getItem("offset");
    if (newpageIndex === 0) {
      setNewpageIndex(0);
      localStorage.setItem("offset", 0);
      localStorage.setItem("newpageIndex", 0);
    } else {
      const num = ThisOffsethandle.toString();
      const str = num.split("").reverse().join("");
      const result = str.replace(/^0+/, "");
      // localStorage.setItem("newpageIndex", Number(ThisOffsethandle == 0 ? 0 : result))
      setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result));
    }
  }, []);

  eventBus.$on("showSelectTabs", (data) => showSelectTabs(data));
  function showSelectTabs(data) {
    setNewpageIndex(0);
  }
  const totalRecords = response;
  for (let i = 0; i < response; ) {
    i = i + Number(selectTotalShow);
    pageOptionsFirst.push(i);
  }
  pageOptionsFirst.map((prop, key) => {
    const num = prop.toString();
    if (num >= 100) {
      const str = num.substring(0, num.length - 1);
      pageOptions1.push(str - 1);
    } else {
      const str = num.split("").reverse().join("");
      const result = str.replace(/^0+/, "");
      pageOptions1.push(result - 1);
    }
  });

  eventBus.$on("MoveMenuResponseComplete", (data) => MoveMenuResponseComplete(data));
  function MoveMenuResponseComplete(data) {
    const finaldata = (Number(data) - 10).toString();
    const str = finaldata.split("").reverse().join("");
    const result = str.replace(/^0+/, "");
    setNewpageIndex(Number(result));
  }

  eventBus.$on("showInvoiceStatus", (data) => showInvoiceStatus(data));
  function showInvoiceStatus(data) {
    const found1 = data?.find((obj) => {
      const PendingCheck = obj.ocrStatus === "PENDING";
      const InProgressCheck = obj.ocrStatus === "INPROGRESS";
      const CompletCheck = obj.ocrStatus === "COMPLETED";
      return CompletCheck;
    });

    if (found1) {
      setSelectAllButton(false);
    } else {
      setSelectAllButton(true);
    }
  }

  const IndeterminateCheckbox = React.forwardRef(({ des, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    // const checkDes = des === 'In Progress' || des === 'Synced to Quickbooks' ? true : false;
    const checkDes1 = false;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <ThemeProvider theme={theme}>
        <MDBox display="flex" alignItems="center">
          <Checkbox
            ref={resolvedRef}
            disabled={checkDes1}
            onClick={() => setRetrySuccess(false)}
            {...rest}
          />
          <MDBox ml={1}>
            <MDTypography variant="caption" fontWeight="medium" color="text"></MDTypography>
          </MDBox>
        </MDBox>
      </ThemeProvider>
    );
  });

  const [checkedCount, setCheckedCount] = useState(0);

  const HandleChangeCheckBoxOrPage = (pageIndex, selectedRowIds) => {
    eventBus.$emit("ChangePaginationPage", pageIndex);

    if (
      Object.keys(selectedRowIds).length !== 0 &&
      checkedCount !== Object.keys(selectedRowIds).length
    ) {
      setCheckedCount(Object.keys(selectedRowIds).length);

      if (Object.keys(selectedRowIds).length === 5) {
        window.scrollTo({
          top: Math.min(window.scrollY + 1, document.body.scrollHeight), // Scroll to a minimum of 100 pixels from the current scroll position or the bottom of the page
          behavior: "smooth",
        });
      } else if (Object.keys(selectedRowIds).length === 4) {
        window.scrollTo({
          top: Math.min(window.scrollY + 1, document.body.scrollHeight), // Scroll to a minimum of 100 pixels from the current scroll position or the bottom of the page
          behavior: "smooth",
        });
      }
    }
  };

  eventBus.$on("RetrySuccessFullyComplete", (data) => RetrySuccessFullyComplete(data));
  function RetrySuccessFullyComplete(data) {
    setRetrySuccess(true);
  }

  const ALLselectTab = true;
  const tableInstance = useTable(
    { columns, data, selectTab, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection

        selectTab === "" || showCheck == false
          ? { ...columns }
          : {
              id: "selection",
              width: selectWidth,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <>
                  {/* <IndeterminateCheckbox disabled={selectAllButton} {...getToggleAllPageRowsSelectedProps()} sx={{ pl: 1 }} /> */}
                </>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <>
                  <IndeterminateCheckbox
                    des={row?.values?.status?.props?.children?.props?.children}
                    {...row.getToggleRowSelectedProps()}
                    disabled={
                      retrySuccess || selectTotalId.length < 5
                        ? false
                        : checkedCount === 5 && !selectedRowIds[row.id]
                    }
                  />
                </>
              ),
            },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    onPageChange = HandleChangeCheckBoxOrPage(pageIndex, selectedRowIds),
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
  useEffect(() => {
    var tempId = [];
    var arr = [];
    tempId = selectedFlatRows.map((d) => (d.original.id ? d.original.id : 123));
    // setSelectedRows(tempId);
    var value = 123;
    arr = tempId.filter(function (item) {
      return item !== value;
    });
    setSelectTotalId(arr);
    eventBus.$emit("invoiceRowSelected", { selectedRowIds: arr });
  }, [selectedRowIds]);

  const handleChange = (e, p) => {
    setPages(p);
    const dataStr = (p - 1).toString() + 0;
    localStorage.setItem("offset", Number(dataStr));
    eventBus.$emit("onclickchangePages", Number(p - 1)) || setNewpageIndex(Number(p - 1));
  };

  const setEntriesPerPage = (value) => setPageSize(value);

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions1.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedpageOptions1 = pageOptions1.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [pages, setPages] = useState(1);

  const ThisOffsetuse = localStorage.getItem("offset");
  const finalPage = ThisOffsetuse == 0 ? 1 : pages;
  const isFirstPage = finalPage === 1;
  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions1.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const changePerPageEntries = (value) => {
    setNewpageIndex(0);
    setEntriesPerPage(parseInt(value, 10));
    setSelectTotalShow(value);
    const NewForOffset = Number(value) === 10 ? null : Number(value);
    localStorage.setItem("newOffsetForAll", NewForOffset);
    eventBus.$emit("onclickchangeTotalPerPage", Number(value));
  };

  return (
    <TableContainer sx={{ boxShadow: "none", backgroundColor: darkMode ? "#202940" : "#ffffff" ,height:'67vh' }}>
      {/* <EnhancedTableToolbar numSelected={SelectedRows.length} /> */}
      {/* {entriesPerPage || canSearch ? (
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {canSearch && (
                        <MDBox width="12rem" ml="auto">
                            <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    )}
                </MDBox>
            ) : null} */}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} 
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: darkMode ? "#202940" : "#ffffff",
              zIndex: 100,
            }}
            >
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? "auto" : "auto"}
                  align={showCheckBoxs === false ? "left" : "center"}
                  // ml={showCheck == false ? -60 : 0}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    showCheckBoxs={showCheckBoxs}
                    ClientHeigth={ClientHeigth}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={showCheckBoxs === false ? "left" : "center"}
                    ml={-60}
                    selectHeight={selectTab}
                    // width={cell.column.Header === 'Vendor' ? 300 : "max - content"}
                    // display={cell.column.Header === 'Vendor' ? "block" : 'inline-block'}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {response == 0 && (
        <MDTypography
          variant="body2"
          color="secondary"
          sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "15%", marginBottom: "5%" }}
        >
          No records found
        </MDTypography>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={!showTotalEntries && pageOptions1.length === 1 ? 0 : 3}
        right={25}
        position="fixed"  // Use "fixed" positioning
        bottom={20}        // Stick to the bottom of the viewport
        zIndex={1000}     // Ensure it's above other content if necessary
        bgcolor= {darkMode ? "#202940" :"background.paper"}
        width="91.2%"
        borderTop="1px solid #ddd"
        height="48px"
      >
        <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}  mb={4}>
          {response > 1 && (
            <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                sx={
                  darkMode
                    ? { width: "5rem", background: "white", borderRadius: "8px" }
                    : { width: "5rem" }
                }
                onChange={(event, newValue) => {
                  changePerPageEntries(newValue);
                }}
                size="small"
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color={darkMode ? "light" : "secondary"}>
                &nbsp;&nbsp;entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions1.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {
                <Pagination
                page={finalPage}
                color="primary"
                sx={{ justifyContent: "center", marginTop: 2, mr: 1.2 }}
                onChange={handleChange}
                count={pageOptionsFirst.length}
                renderItem={(item) => (
                  <>
                    {item.type === 'previous' && isFirstPage ? null : (
                      <PaginationItem
                        {...item}
                        sx={{
                          color: "#237dea",
                          "&.Mui-selected": {
                            backgroundColor: "#237dea",
                            color: "white",
                          },
                        }}
                        components={{
                          next: () => <>{item.type === 'next' && 'Next'}</>,
                          previous: () => <>{item.type === 'previous' && 'Previous'}</>,
                        }}
                      />
                    )}
                  </>
                )}
              />
              }
            </MDPagination>
          )}
          {response >= 1 && (
            <MDBox sx={{ mr: 1.2, mb: -1.5 }}>
              <MDTypography
                variant="button"
                color={darkMode ? "light" : "secondary"}
                fontWeight="regular"
              >
                Showing{" "}
                {newpageIndex == 0
                  ? "1"
                  : finalPage * Number(selectTotalShow) - Number(selectTotalShow)}{" "}
                to{" "}
                {response < finalPage * Number(selectTotalShow)
                  ? response
                  : finalPage * Number(selectTotalShow)}{" "}
                of {response} entries
              </MDTypography>
            </MDBox>
          )}
          {/* {response > 1 &&
                        <MDBox sx={{ mb: -1.4 }} >
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                                Showings {(newpageIndex) == 0 ? '01' : `${(newpageIndex * Number(selectTotalShow))}`} to {response < Number(`${pages - 1}0`) + Number(selectTotalShow) ? response : Number(selectTotalShow) * pages} of {response} entries
                            </MDTypography>
                        </MDBox>} */}
        </MDBox>
      </Box>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: false,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
