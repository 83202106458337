import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useMaterialUIController } from "context";

export const handleValueChange = (name, setFieldValue) => (val) =>
  setFieldValue(name, val.floatValue);

const NumberFieldText = ({ currencySymbol, ...props }) => {
  const [displayValue, setDisplayValue] = useState();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <NumberFormat
      customInput={TextField}
      variant="standard"
      isNumericString={true}
      value={displayValue}
      fixedDecimalScale={true}
      sx={{
        color: darkMode ? "#ffffff" : "#000",
        borderBottom: darkMode ? "2px solid #ffffff" : "2px solid #000000",
      }}
      onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
      InputProps={{
        style: { color: darkMode ? "#ffffff" : "#000" },
      }}
      InputLabelProps={{
        style: { color: darkMode ? "#ffffff" : "#000000" },
      }}
      {...props}
    />
  );
};

export default NumberFieldText;
