import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { SITE_URL } from "helpers/config";
import { User1 } from "helpers/getUser";
import UserHoc from "hoc/userHoc";
import { AddNewSchema } from "layouts/accountants/user/components/schemas/addnewvalidations";
import initialValues from "layouts/accountants/user/components/schemas/initialValues";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import FormField from "layouts/admin/accountants/components/FormField";
import { CircularProgress, InputAdornment, TextField,FormHelperText  } from "@mui/material";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import {
  AddNewuser,
  EditUserInfo,
  GetRolescope,
  GetuserClientList,
  checkEmailUser,
} from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DataTable from "../../../components/Tables/DataTable";
import MDInput from "components/MDInput";
import { padding, width } from "@mui/system";
import RestoreIcon from "@mui/icons-material/Restore";
import DialogContentText from "@mui/material/DialogContentText";
import { useMaterialUIController } from "context";

function AddNewUser() {
  let newObj = {};
  const navigate = useNavigate();
  const [adminRoleId, setAdminRoleId] = useState("");
  const [clientRoleId, setclientRoleId] = useState("");
  const [managerRoleId, setManagerRoleId] = useState("");
  const [ownerRoleId, setOwnerRoleId] = useState("");
  const [startCheckLoader,setStartCheckLoader] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isDisable1, setIsDisable1] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarErrorMessage, setSnakbarErrorMessage] = useState("");
  const [allClient, setAllClient] = useState(true);
  const [openClient, setOpenClient] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [openRestoreDilog, setOpenRestoreDilog] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [checked, setChecked] = useState(newObj);
  const [showCheck, setShowCheck] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [clientRecord, setClientRecord] = useState([]);
  const [permissionsData, setPermissionsData] = useState({});
  const [emailId, setemailId] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [showClickHere, setShowClickHere] = useState(false);
  const [styleMt, setStyleMt] = useState("0px");
  const [styleMt1, setStyleMt1] = useState("0px");
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [userId, setUserId] = useState();
  const [phone, setPhone] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewuser, { onLoad: false });
  const [
    { isLoading: isClientError, isError: isClientLoading, response: Clientresponse },
    setClientAPICall,
  ] = useAPICall(GetuserClientList, { onLoad: false });
  const [
    { isLoading: isRoleLoading, isError: isRoleError, response: roleResponse },
    getRoleAPICall,
  ] = useAPICall(GetRolescope, { onLoad: false });
  const [
    { isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse },
    checkEmailAPICall,
  ] = useAPICall(checkEmailUser, { onLoad: false });
  const [
    { isLoading: isUpdateLoading, isError: isUpdateError, response: UpadateResponse },
    UpdateAPICall,
  ] = useAPICall(EditUserInfo, { onLoad: false });

  useEffect(() => {
    if (isUpdateLoading) {
      return () => null;
    }
    if (isUpdateError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (UpadateResponse && UpadateResponse.data.isSuccess === true) {
      setLoadingCircul(false);
      setLoading(false);
      navigate("/accountant/users/list");
    }
  }, [UpadateResponse]);

  useEffect(() => {
    if (isCheckEmError) {
      setStartCheckLoader(false);
      return () => null;
    }
    if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
      setEmailStatus(checkEmResponse.data.data.status);
      setUserId(checkEmResponse.data.data.userId);
      if (checkEmResponse.data.data.status === "ARCHIVED") {
        setStyleMt("6px");
        setShowClickHere(true);
        setErrorMessages(`${checkEmResponse?.data?.data?.message} `);
      } else if (checkEmResponse.data.data.status === "ACTIVE") {
        setStyleMt("6px");
        setShowClickHere(false);
        setErrorMessages(checkEmResponse?.data?.data?.message);
      } else if (checkEmResponse.data.data.status === "BLOCKED") {
        setStyleMt("6px");
        setShowClickHere(true);
        setErrorMessages(`${checkEmResponse?.data?.data?.message} `);
      } else {
        setShowClickHere(false);
        setStyleMt("0px");
        setErrorMessages("");
        setIsDisable(false);
      }
      setStartCheckLoader(false);
    }
  }, [checkEmResponse]);

  const handleCloseRestoreDilog = () => {
    setOpenRestoreDilog(false);
  };
  const handleRestoreNone = async () => {
    console.log("none");
  };

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (emailId.length !== 0) {
        setStartCheckLoader(true);
        await checkEmailAPICall({
          payload: [
            {
              email: `${emailId}`,
              checkBy: "email",
            },
          ],
        });
      } else {
        setStartCheckLoader(false);
        setIsDisable(true);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [emailId]);

  useEffect(
    (e) => {
      const data = clientRecord.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          id: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id, prop.name)}
                checked={checked[prop._id]}
              />
            </MDBox>
          ),
          snum: key + 1,
          client: prop.name,
          approve: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id, prop.name)}
                // checked={checked[prop._id]}
              />
            </MDBox>
          ),
          doctoreview: prop.toReview,
          unmappedvendors: prop.unmappedVendor,
          clientapproval: Createdate.toLocaleDateString(),
          yourapproval: prop.approved,
          pendingapproval: prop.pendingApproval,
          unreconciled: "-",
        };
      });
      setAdminList(data);
    },
    [checked]
  );

  const RenderDilog = () => {
    setClientList(adminList);
  };

  const toggleCheck = async (clientId, clientName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[clientId] = !prevState[clientId];
      return newState;
    });

    // setSelectedClientId(selectedClientId);
    if (selectedClientId.includes(clientId)) {
      // selectedClientId.pop(clientId);
      let newArr = selectedClientId.filter(function (ele) {
        return ele != clientId;
      });

      delete permissionsData[clientId];
      // newArr.shift();
      setSelectedClientId(newArr);
    } else {
      selectedClientId.push(clientId);
      permissionsData[clientId] = {
        clientId: clientId,
        name: clientName,
        roleId:
          selectedRole === "domain1"
            ? adminRoleId
            : selectedRole === "domain"
            ? managerRoleId
            : clientRoleId,
      };
    }
    setPermissionsData(permissionsData);
  };

  useEffect(async () => {
    await getRoleAPICall({
      payload: [],
    });
  }, []);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      setShowErrorSnackbar(true);
      setBtnDisable1(false);
      setIsDisable1(false);
      setSnakbarErrorMessage(response.data.message);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 10000);
      setIsDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      navigate("/accountant/users/list");
    }
  }, [response]);

  useEffect(() => {
    if (isRoleLoading) {
      return () => null;
    }

    if (isRoleError) {
      // response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
      return () => null;
    }

    if (roleResponse && roleResponse.data.isSuccess === true) {
      const adminresult = roleResponse.data.data.records.find(
        ({ template }) => template === "admin"
      );
      const managerresult = roleResponse.data.data.records.find(
        ({ template }) => template === "manager"
      );
      const clientresult = roleResponse.data.data.records.find(
        ({ template }) => template === "user"
      );
      const ownerresult = roleResponse.data.data.records.find(
        ({ template }) => template === "owner"
      );
      setAdminRoleId(adminresult?._id);
      setclientRoleId(clientresult?._id);
      setOwnerRoleId(ownerresult?._id);
      setManagerRoleId(managerresult?._id);
      // navigate('/accountants/list');
    }
  }, [roleResponse]);

  useEffect(() => {
    if (isClientLoading) {
      return () => null;
    }
    if (isClientError) {
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }
    if (Clientresponse && Clientresponse.data.isSuccess === true) {
      setClientRecord(Clientresponse.data.data.records);
      setTableEmpty(Clientresponse.data.data.totalRecords);
      const data = Clientresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          id: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id, prop.name)}
                checked={checked[prop._id]}
              />
            </MDBox>
          ),
          snum: key + 1,
          client: prop.name,
          approve: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id, prop.name)}
                // checked={checked[prop._id]}
              />
            </MDBox>
          ),
          doctoreview: prop.toReview,
          unmappedvendors: prop.unmappedVendor,
          clientapproval: Createdate.toLocaleDateString(),
          yourapproval: prop.approved,
          pendingapproval: prop.pendingApproval,
          unreconciled: "-",
        };
      });
      setAdminList(data);
      setLoading(false);
    }
  }, [Clientresponse]);

  const handleClickOpenClient = async () => {
    setLoading(true);
    await setClientAPICall({
      payload: [{ keyword: `${search}` }],
    });
    setOpenClient(true);
  };

  const handleCloseClient = () => {
    setOpenClient(false);
  };

  const handleChanges = () => {
    setAllClient(!allClient);
  };

  const handleSubmit = async (values) => {
    setSelectedRole(values.role);
    setBtnDisable1(true);
    setIsDisable(true);
    setIsDisable1(true);
    // const roleScope = values.role === 'domain1' ? 'domain' : values.role;
    {
      values.role === "domain1"
        ? await setAPICall({
            payload: [
              {
                email: emailId,
                // "businessName": `${ values.businessname }`,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                // "scope": `${roleScope}`,
                returnLink: `${SITE_URL}/user/create-password`,
                // "roleId": RoleId,
                // "allClient": true,
                permissions: [
                  {
                    category: "accounting",
                    role: "admin",
                    allScopes: true,
                  },
                ],
                // "permissions": Object.values(permissionsData),
              },
            ],
          })
        : allClient == true
        ? values.role === "domain"
          ? await setAPICall({
              payload: [
                {
                  email: emailId,
                  phone: phone,
                  fname: `${values.firstname}`,
                  lname: `${values.lastname}`,
                  returnLink: `${SITE_URL}/user/create-password`,
                  permissions: [
                    {
                      category: "accounting",
                      role: "manager",
                      allScopes: true,
                    },
                  ],
                },
              ],
            })
          : await setAPICall({
              payload: [
                {
                  email: emailId,
                  phone: phone,
                  fname: `${values.firstname}`,
                  lname: `${values.lastname}`,
                  returnLink: `${SITE_URL}/user/create-password`,
                  permissions: [
                    {
                      category: "accounting",
                      role: "user",
                      allScopes: true,
                    },
                  ],
                },
              ],
            })
        : values.role === "domain"
        ? await setAPICall({
            payload: [
              {
                email: emailId,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                returnLink: `${SITE_URL}/user/create-password`,
                permissions: [
                  {
                    category: "accounting",
                    role: "manager",
                    scopeIds: selectedClientId,
                  },
                ],
              },
            ],
          })
        : await setAPICall({
            payload: [
              {
                email: emailId,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                returnLink: `${SITE_URL}/user/create-password`,
                permissions: [
                  {
                    category: "accounting",
                    role: "user",
                    scopeIds: selectedClientId,
                  },
                ],
              },
            ],
          });
    }
  };

  const dataTableData = {
    columns: [
      { Header: "Select", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
      {
        Header: "Client",
        accessor: "client",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      //  { Header: "Can Approve", accessor: "approve", Cell: ({ value }) => <DefaultCell value={value} /> },
    ],

    rows: adminList,
  };

  const handleRestore = async () => {
    setOpenRestoreDilog(true);
  };

  const handleRestoreClick = async () => {
    setLoadingCircul(true);
    setOpenRestoreDilog(false);
    if (emailStatus === "ARCHIVED") {
      await UpdateAPICall({
        payload: [
          {
            bussinessId: `${userId}`,
            isArchived: false,
          },
        ],
      });
    } else if (emailStatus === "BLOCKED") {
      await UpdateAPICall({
        payload: [
          {
            bussinessId: `${userId}`,
            isBlocked: false,
          },
        ],
      });
    } else {
      navigate("/accountant/users/list");
    }
  };

  let emails = "";
  const handleChange = (e) => {
    if(e?.target?.value.length == 0){
      setStartCheckLoader(false);
    }
    setErrorMessages("");
    setemailId(e.target.value);
    emails = e.target.value;
  };
  const validate = (value) => {
    const finalEmail = emails ? emails : emailId.slice(0, -1);
    let errorMessage;
    if (!finalEmail) {
      errorMessage = "Email address is required";
      setStyleMt1("2px");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(finalEmail)) {
      errorMessage = "Your email address is invalid";
      setStyleMt1("2px");
    } else {
      setStyleMt1("20px");
      errorMessage = "";
    }
    return errorMessage;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={AddNewSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldTouched, handleBlur }) => (
                <Form autoComplete="off">
                  <Card
                    sx={{
                      height: "100%",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    }}
                  >
                    <MDBox p={3}>
                      <MDBox>
                        <MDBox lineHeight={0}>
                          <MDTypography variant="h5">Add New User</MDTypography>
                        </MDBox>
                        <MDBox mt={1.625}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="First Name *" name="firstname" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Last Name *" name="lastname" />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} marginBottom={2} >
                          <Field
                            name="email"
                            validate={validate}
                            
                          >
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="Email *"
                                variant="standard"
                                fullWidth
                                value={emailId}
                                InputProps={{
                                  endAdornment: startCheckLoader ? (
                                    <InputAdornment position="end">
                                      <CircularProgress size={20} />
                                    </InputAdornment>
                                  ) : null,
                                }}
                                InputLabelProps={{
                                  style: darkMode ? { color: "#ffffff" } : {}// Change label color here
                                }}
                                helperText={touched.email && errors.email ? (
                                  <FormHelperText sx={{ color: 'red' }}>
                                    {errors.email}
                                  </FormHelperText>
                                ) : null}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleBlur(e);
                                  setStartCheckLoader(true);
                                }}
                              />
                            )}
                          </Field>
                          <MDBox>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {
                                <div
                                  style={{
                                    color: "#fb8c00",
                                    cursor: "pointer",
                                    marginTop: styleMt,
                                  }}
                                  onClick={
                                    emailStatus != "ACTIVE" ? handleRestore : handleRestoreNone
                                  }
                                >
                                  {errorMessages}
                                </div>
                              }
                            </MDTypography>
                          </MDBox>
                        </Grid>
                          <Grid item xs={12}>
                            <NumberFormat
                              customInput={TextField}
                              variant="standard"
                              type="tel"
                              fullWidth
                              value={phone}
                              label="Phone Number"
                              InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                              InputProps={{ style: darkMode ? { color: "#ffffff",borderBottom:"2px solid" } : {} }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                                mb: 1,
                                mt: -1,
                                fontSize: `0.823rem !important`,
                              }}
                              format="(###) ###-####"
                              mask=""
                              onValueChange={(value) => setPhone(value.formattedValue)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                              <InputLabel sx={darkMode ? { mt: -3, color: "#ffffff" } : { mt: -3 }}>
                                Role *
                              </InputLabel>
                              <Field
                                as="select"
                                name="role"
                                variant="standard"
                                style={
                                  darkMode
                                    ? {
                                      width:"100%",
                                        marginTop: "18px",
                                        borderTop: "0px",
                                        borderLeft: "0px",
                                        borderRight: "0px",
                                        color: "lightgrey",
                                        borderBottom: "2px solid",
                                        backgroundColor: "transparent",
                                        borderRadius : '0px',
                                        
                                      }
                                    : {
                                      width:"100%",
                                        marginTop: "18px",
                                        borderTop: "0px",
                                        borderLeft: "0px",
                                        borderRight: "0px",
                                        borderRadius : '0px',
                                        color: "slategray",
                                      }
                                }
                              >
                                <option
                                  style={
                                    darkMode ? { color: "#ffffff", backgroundColor: "#202940" } : {}
                                  }
                                  value=""
                                >
                                  Select Role
                                </option>

                                {Object.keys(User1).map((key) => (
                                  <option
                                    style={
                                      darkMode
                                        ? { color: "#ffffff", backgroundColor: "#202940" }
                                        : {}
                                    }
                                    value={key}
                                  >
                                    {User1[key]}
                                  </option>
                                ))}
                              </Field>
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  <ErrorMessage name="role" />
                                </MDTypography>

                                <>
                                  {values.role == "domain1" ? (
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="info"
                                      fontWeight="regular"
                                    >
                                      This user will have access to the entire Paynthr account and
                                      be able to add/edit users.
                                    </MDTypography>
                                  ) : (
                                    ""
                                  )}
                                  {values.role == "domain" ? (
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="info"
                                      fontWeight="regular"
                                    >
                                      This user will have access to the entire account except the
                                      billing page and be able to add/edit users
                                    </MDTypography>
                                  ) : (
                                    ""
                                  )}
                                  {values.role == "client" ? (
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="info"
                                      fontWeight="regular"
                                    >
                                      This user will have only have access to the Documents &
                                      Inventory page
                                    </MDTypography>
                                  ) : (
                                    ""
                                  )}
                                </>
                                <>{setSelectedRole(values.role)}</>
                              </MDBox>
                            </FormControl>
                          </Grid>
                          <>
                            {values.role === "client" || values.role === "domain"
                              ? setShowCheck(true)
                              : setShowCheck(false)}
                          </>
                          <>
                            {showCheck && (
                              <Grid item xs={12}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="allClient"
                                    control={<Checkbox />}
                                    label="All Clients"
                                    checked={allClient}
                                    onChange={handleChanges}
                                    sx={{ mt: 1.5 }}
                                  />
                                </MDBox>
                                {!allClient ? (
                                  <MDBox sx={{ mt: 1 }}>
                                    <Button
                                      // color="info"
                                      style={{ color: "white" }}
                                      onClick={() => {
                                        handleClickOpenClient();
                                      }}
                                      variant="contained"
                                    >
                                      {selectedClientId.length > 0 && !allClient
                                        ? `${selectedClientId.length} Selected Clients`
                                        : `Select Clients`}
                                    </Button>
                                  </MDBox>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            )}
                          </>
                        </MDBox>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                          <MDButton
                            disabled={isDisable1}
                            variant="gradient"
                            color="light"
                            component={Link}
                            to="/accountant/users/list"
                          >
                            Cancel
                          </MDButton>
                          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                            <MDButton
                              disabled={isDisable}
                              type="submit"
                              variant="gradient"
                              color="info"
                            >
                              {btnDisable1 ? "creating..." : "create"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <MDBox>
          {loading && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          <Dialog open={openClient} onClose={handleCloseClient} fullWidth="true">
            <DialogTitle>SELECT CLIENTS</DialogTitle>
            <DialogContent>
              <MDBox my={5} sx={{ mt: 1 }}>
                <MDBox>
                  {loading && (
                    <CircularProgress
                      size={50}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </MDBox>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={true}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClient} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={openRestoreDilog}
            onClose={handleCloseRestoreDilog}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px",
                },
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                justifyContent: "center",
                display: "flex",
                fontSize: 70,
              }}
            >
              <RestoreIcon sx={{ color: green[500], fontSize: 50 }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ display: "flex", justifyContent: "center", fontSize: "1rem" }}
              >
                <h1>Are you sure?</h1>
              </DialogContentText>
              <DialogContentText
                sx={{ display: "flex", justifyContent: "center", fontSize: "0.90rem" }}
              >
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ fontWeight: 200, fontSize: "1.20rem" }}
                >
                  {emailStatus == "ARCHIVED"
                    ? `Are you sure you want to restore account?`
                    : `Are you sure you want to unblock account?`}
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mb: 2, mt: 1 }}>
              <MDButton variant="gradient" color="success" onClick={handleRestoreClick}>
                Yes, restore it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={handleCloseRestoreDilog}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed!"
          // autoHideDuration={3000}

          content={SnackbarErrorMessage}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(AddNewUser);
