import Request from "./apicallservices";


export const GetclientUserList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/employees?keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}&isArchived=false`;
    } else {
        var apiUrl = `/clientusers/employees?limit=${params.limit ? params.limit : 10}&offset=${params.offset}&isArchived=false`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserClientList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/clients?keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    } else if (params.businessView) {
        var apiUrl = `/clientusers/clients?businessView=${params.businessView}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    } else if (params.businessView && params.keyword) {
        var apiUrl = `/clientusers/clients?businessView=${params.businessView}&keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    } else {
        var apiUrl = `/clientusers/clients?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserClientDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetDeleteList = async (params) => {
    if (params.isArchived && params.keyword) {
        var apiUrl = `/clients/employees?isArchived=${params.isArchived}&keyword=${params.keyword}`;
    } else if (params.isArchived) {
        var apiUrl = `/clients/employees?isArchived=${params.isArchived}`;
    } else {
        var apiUrl = `/clients/employees`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const AddNewClientUser = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/employees`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClientUserProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClientUserInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/employees/${params.bussinessId}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientUserProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetClientUserRolescope = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/roles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientdetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/employees/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetInvoiceList = async (params) => {
    const { businessId, documentStatus, limit, offset, keyword, vendorName, invoiceNo, createdAt, ocrStatus } = params;

    const baseUrl = `/clientusers/clients/${businessId}/documents`;
    const queryParams = {
        documentStatus,
        limit: limit || 10,
        offset,
        keyword,
        vendorName: vendorName || null,
        invoiceNo: invoiceNo || null,
        createdAt: createdAt || null,
        ocrStatus: ocrStatus || null
    };
    
    // Remove null or undefined values from queryParams
    Object.keys(queryParams).forEach(key => (queryParams[key] == null) && delete queryParams[key]);
    
    const queryString = new URLSearchParams(queryParams).toString();
    const apiUrl = `${baseUrl}?${queryString}`;
    // if (params.keyword) {
    //     var apiUrl = `/clientusers/clients/${params.businessId}/documents?documentStatus=${params.documentStatus}&keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`
    // } else {
    //     var apiUrl = `/clientusers/clients/${params.businessId}/documents?documentStatus=${params.documentStatus}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    // }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetStatusCount = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents-count`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetInvoiceDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}`;
        delete params.documentId;
        delete params.businessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetItemList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/documents/${params.documentId}/items?isArchived=false`;
        delete params.documentId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/documents/${params.documentId}/items`;
        delete params.documentId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/documents/${params.documentId}/items/${params.ItemId}`;
        delete params.documentId;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAuditLogList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}/logs`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCommentsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}/comments`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const CreateComments = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}/comments`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const UpdateInvoiceDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClientUserItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/documents/${params.documentId}/items/${params.ItemId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/documents/${params.InvoiceId}/items/${params.ItemId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const MoveMenuInvoice = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents`;
        const req = new Request(apiUrl, params.documentIds, params.documentStatus);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteInvoice = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clients/invoices/delete`;
        const req = new Request(apiUrl, params.invoiceIds);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditVendorDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clients/vendors/${params.VendorId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetIssueList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.documentId}/issues?documentType=ITEM`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetVendorList = async (params) => {
    if (params.IsSynced) {
        var apiUrl = `/clientusers/vendors?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&limit=5000`;
    }
    else if (params.keyword && params.keyword) {
        var apiUrl = `/clientusers/vendors?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=${params.IsSynced}&limit=5000`;
    } else if (params.keyword) {
        var apiUrl = `/clientusers/vendors?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&limit=5000`;
    } else {
        var apiUrl = `/clientusers/vendors?clientId=${params.businessId}&isArchived=false&limit=5000`;
    }
    delete params.businessId;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetCustomerList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/customers?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000`;
    } else {
        apiUrl = `/clientusers/customers?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000`;
    }
    // `/clientusers/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCategoryList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/finaccounts?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000`;
    } else {
        var apiUrl = `/clientusers/finaccounts?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000`;
    }
    // `/clientusers/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClassesList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/classes?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000`;
    } else {
        var apiUrl = `/clientusers/classes?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000`;
    }
    // `/clientusers/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProductsList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/products?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000`;
    } else {
        var apiUrl = `/clientusers/products?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000`;
    }
    // `/clientusers/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const checkEmail = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/employees/check`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetclientRestaurantList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/restaurants?keyword=${params.keyword}&limit=10&offset=${params.offset}&isArchived=false`;
    } else {
        var apiUrl = `/clientusers/restaurants?limit=10&offset=${params.offset}&isArchived=false`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetclientRestaurantNoLimitList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/restaurants?keyword=${params.keyword}&limit=1000&offset=0&isArchived=false`;
    } else {
        var apiUrl = `/clientusers/restaurants?limit=1000&offset=0&isArchived=false`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewRestaurantApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetRestaurantdetails = async (params) => {
    const finalResID = params.restaurantId == ':id' ? location.pathname.split("/").slice(-2)[0] : params.restaurantId
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${finalResID}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditRestaurantInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params.businessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetPaymentagreement = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/payment-intent`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCardDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/card`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientUserBilling = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/invoices`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientLogsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/invoices/${params.ClientId}/breakups`;
        delete params.ClientId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetUserProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetInventoryList = async (params) => {
    // ${ params?.moveTabs }
    const finalResID = params.restaurantId == ':id' ? location.pathname.split("/").slice(-2)[0] : params.restaurantId
    if (params?.finalQuery && params?.moveTabs) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?${params?.moveTabs}&${params?.finalQuery}&offset=0&limit=1000`;
    } else if (params?.keyword || params?.finalQuery && params?.locationId) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?${params?.finalQuery}&locationId=${params.locationId}&offset=0&limit=1000`;
    } else if (params?.finalQuery) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?${params?.finalQuery}&offset=0&limit=1000`;
    } else if (params?.currentInventory && params?.locationId) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?currentInventory=${params.currentInventory}&locationId=${params.locationId}&offset=0&limit=1000`;
    } else if (params?.moveTabs) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?${params?.moveTabs}&offset=0&limit=1000`;
    } else if (params?.locationId) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?locationId=${params.locationId}&offset=0&limit=1000`;
    } else {
        var apiUrl = `/clientusers/restaurants/${finalResID}/inventories?offset=0&limit=1000`;
    }
    return new Promise(async (resolve, reject) => {
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetInventoryUnitList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/inventory-units`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewInventoryUnitApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/inventory-units`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetRestaurantLocationList = async (params) => {
    const finalResID = params.businessId == ':id' ? location.pathname.split("/").slice(-2)[0] : params.businessId
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${finalResID}/locations?inventoryBlocked=${false}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddVendors = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/vendors/`;
        delete params.VendorId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewLocationApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/locations`;
        delete params.VendorId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewInvenory = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/inventories/`;
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetgetInventoryDetail = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/inventories/${params?.inventoryId}`;
        delete params.inventoryId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditInvenory = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/products/${params?.productId}`;
        delete params?.restaurantId;
        delete params?.productId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditRapidInvenory = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/inventories`;
        delete params?.restaurantId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}



export const CreateClientusersClient = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const UpdateClientusersClient = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}



export const EditBusinesss = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.businessId}`;
        delete params.businessId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const ExportDocument = async (params) => {
    const finalResID = params.restaurantId == ':id' ? location.pathname.split("/").slice(-2)[0] : params.restaurantId
    if (params?.finalQuery && params?.moveTabs) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/export?${params?.moveTabs}&${params?.finalQuery}&offset=0&limit=1000`;
    } else if (params.finalQuery) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/export?${params?.finalQuery ? params?.finalQuery : ''}&offset=0&limit=1000`;
    } else if (params.moveTabs) {
        var apiUrl = `/clientusers/restaurants/${finalResID}/export?${params?.moveTabs ? params?.moveTabs : ''}&offset=0&limit=1000`;
    } else {
        var apiUrl = `/clientusers/restaurants/${finalResID}/export`
    }
    return new Promise(async (resolve, reject) => {
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const UpdateRestaurantOrderList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/locations/${params.locationId}/change-inventory-order`;
        delete params.businessId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetLocationDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/locations/${params.locationId}`;
        delete params.businessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const LocationInventoryStrat = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/locations/${params.locationId}/inventorystart`;
        delete params.locationId;
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const LocationInventoryStop = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/locations/${params.locationId}/inventoryend`;
        delete params.locationId;
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetinventoryHistory = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/products/${params?.productId}/logs`;
        delete params.productId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const CheckProductExist = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params?.restaurantId}/checkproduct`;
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProductList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/clients/${params?.clientId}/products?keyword=${params.keyword}`;
    } else {
        var apiUrl = `/clientusers/clients/${params?.clientId}/products`;
    }
    return new Promise(async (resolve, reject) => {

        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const removeRestaurantAPI = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params.restaurantId}`;
        delete params.restaurantId;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteInventory = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/restaurants/${params.restaurantId}/inventories/${params.inventoryId}`;
        delete params.restaurantId;
        delete params.inventoryId;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteBusinesss = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/clients/${params.bussinessId}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetclientUserAllList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/clientusers/employees?keyword=${params.keyword}&limit=1000&offset=0&isArchived=false`;
    } else {
        var apiUrl = `/clientusers/employees?limit=1000&offset=0&isArchived=false`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const sendclientReinviteEmailList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/employees/reinvite`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetTransactionsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/clientusers/profiles/invoices/${params.invoiceId}/transactions`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetsummoryList = async (params) => {
    const apiUrl = `/clientusers/clients/${params.businessId}/documents/${params.InvoiceId}/summary`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}