import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Draggable from 'react-draggable';

const NotificationBox = ({ notifications }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [notificationList, setNotificationList] = useState(notifications);

  // Remove notification function
  const handleRemoveNotification = (index) => {
    setNotificationList((prevNotifications) =>
      prevNotifications.filter((_, i) => i !== index)
    );
  };

  // Toggle minimize/maximize function
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  if (notificationList.length === 0) return null;

  return (
    <Draggable>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 25,
          width: 300,
          maxHeight: isMinimized ? 60 : 250, // Minimized height
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: '10px 10px 0 0',
          overflowY: isMinimized ? 'hidden' : 'auto',
          p: 2,
          transition: 'max-height 0.3s ease',
          zIndex: 99999,
          cursor: 'move', // Change cursor to indicate movability
        }}
      >
        {/* Header with minimize/expand button */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography mb={2} variant="h6">
            Notifications
          </Typography>
          <IconButton onClick={toggleMinimize}>
            {isMinimized ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>

        {/* Notification list */}
        {!isMinimized && (
          <List>
            {notificationList.map((notification, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ListItemText
                  primary={notification}
                  primaryTypographyProps={{
                    fontSize: '0.95rem', // Smaller font size for notification text
                  }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Draggable>
  );
};

// Example usage of NotificationBox component
export default NotificationBox;
