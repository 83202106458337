import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { useMaterialUIController } from "context";

const ColumnFilter = forwardRef(({ column, filterText, onFilterChange }, ref) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  
  return (
    (column.headerName !== 'ACTIONS') ?
    <TextField
      variant="outlined"
      size="small"
      // placeholder={column.headerName}
      value={filterText}
      onChange={(event) => onFilterChange(column.field, event.target.value)}
      inputRef={ref}
      sx={{
        marginTop: 1.5,
        width: '100%',
        '& .MuiInputBase-root': {
          height: '25px', // Adjust the height of the input
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiInputBase-input': {
          fontSize: '12px', // Adjust the font size of the input text
          padding: '8px', // Adjust the padding for a smaller height
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: darkMode ? '#ffffff' : '#000000', // Change the border color based on dark mode
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: darkMode ? '#ffffff' : '#000000', // Change border color on hover
        },
      }}
      InputProps={{
        style: {
          fontSize: '12px', // Adjust the placeholder font size
          textAlign: 'center',
          color: darkMode ? '#ffffff' : '#000000',
        },
      }}
    />:
    <div style={{margin:20}}></div>
  );
});

export default ColumnFilter;
