import { Autocomplete, FormControl, Icon, Paper, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { CheckProductExist, GetProductList } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import * as Yup from "yup";
import AddInventoryDialog from "./AddInventoryDialog";
import EditInventoryDialog from "./editInventory";
import { useMaterialUIController } from "context";

function CheckItemExistDialog({ clientId, restaurantsId, onClose, editInventory }) {
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(CheckProductExist, {
    onLoad: false,
  });
  const [
    { isLoading: isProductListLoading, isError: isProductListError, response: ProductListResponse },
    ProductListListAPICall,
  ] = useAPICall(GetProductList, { onLoad: false });
  const [showSubmit, setShowSubmit] = useState(false);
  const [productId, setProductId] = useState("");
  const [openEditInventory, setOpenEditInventory] = useState(false);
  const [openAddInventory, setOpenAddInventory] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [productName, setProductName] = useState("");
  const [newProductName, setNewProductName] = useState("");
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [selectProductName, setSelectProductName] = useState("");
  const [disableSelect, setDisableSelect] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const initialValues = {
    itemName: "",
  };

  useEffect(async () => {
    setDisableSelect(true);
    setLoadingCircul(true);
    await ProductListListAPICall({
      payload: [{ clientId: clientId, keyword: search }],
    });
  }, []);

  useEffect(() => {
    if (isProductListLoading) {
      return () => null;
    }
    if (isProductListError) {
      setDisableSelect(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ProductListResponse && ProductListResponse.data.isSuccess === true) {
      const data = ProductListResponse?.data?.data.map((prop, key) => {
        return {
          name: prop?.name ? prop?.name : "-",
          id: prop?._id ? prop?._id : "-",
        };
      });
      setProductList(data);
      setLoadingCircul(false);
      setDisableSelect(false);
    }
  }, [ProductListResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setShowSubmit(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setProductId(response?.data?.data?.product?._id);
      setBusinessId(response?.data?.data?.product?.business?._id);
      setShowSubmit(false);
      setLoadingCircul(false);
      if (response?.data?.data?.product == null) {
        setOpenAddInventory(true);
      } else {
        eventBus.$emit("editInventoryFound", response);
        // setOpenEditInventory(true);
      }
      // onClose();
    }
  }, [response]);

  const handleSubmit = async (values) => {
    if (values?.name) {
      setShowSubmit(true);
      setNewProductName(values?.name);
      setProductName(values?.name);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            productName: values?.name,
          },
        ],
      });
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              itemName: Yup.string()
                .trim()
                .required("Item  name is required.")
                .min(3, "Item name should be more than 3 characters."),
            })}
            // onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox>
                  <MDBox sx={{ pl: 0, pb: 5, mb: 8.3 }} display="flex" justifyContent="center">
                    <FormControl variant="standard" fullWidth sx={{ m: 2, mb: -2 }}>
                      <Autocomplete
                        sx={
                          darkMode
                            ? {
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                                "& .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white",
                                },
                                "& .MuiFormLabel-root-MuiInputLabel-root": {
                                  color: "white",
                                },
                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                  {
                                    color: "white !important",
                                  },
                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                  {
                                    color: "white !important",
                                  },
                              }
                            : {
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                              }
                        }
                        disabled={disableSelect}
                        id="combo-box-demo"
                        noOptionsText={
                          <MDTypography
                            sx={{ fontSize: "0.775rem", cursor: "pointer", color: "#1a2035" }}
                            onClick={() => handleSubmit({ name: selectProductName })}
                          >
                            {selectProductName} (New Item)
                          </MDTypography>
                        }
                        ListboxProps={{ style: { maxHeight: "6.5rem" }, position: "bottom-start" }}
                        value={{ name: selectProductName }}
                        options={productList}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onInputChange={(event, newValue) => {
                          setSelectProductName(newValue);
                        }}
                        onChange={(event, newValue) => {
                          setProductName(newValue);
                          handleSubmit(newValue);
                        }}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            sx={{ backgroundColor: darkMode ? "#1a2035" : "#ffffff" }}
                          />
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Item Name *"
                            variant="standard"
                            InputLabelProps={{
                              style: { color: darkMode ? "#ffffff" : "#000000" },
                            }}
                            sx={{
                              "& .MuiFormLabel-root": {
                                marginTop: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </MDBox>
                </MDBox>
                {/* <MDBox sx={{
                                    '@media (max-width: 1440px)': {
                                        padding: '0px 16px 14px 16px'
                                    }, padding: '16px 16px 20px 16px'
                                }}  >
                                    <MDBox >
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={12}>
                                                <FormField
                                                    type="text"
                                                    label="Item Name *"
                                                    name="itemName"
                                                />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox mb={-2} sx={{
                                        '@media (max-width: 1440px)': {
                                            marginTop: '16px'
                                        }, marginTop: '40px'
                                    }} display="flex" justifyContent="end">
                                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                            <MDButton
                                                type="submit"
                                                variant="gradient"
                                                disabled={showSubmit}
                                                color="info"
                                            >
                                                Next
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
      <MDBox>
        <Dialog
          open={openAddInventory}
          onClose={() => setOpenAddInventory(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                // height: "50%",
                maxWidth: "570px",
                minHeight: "40%",
                backgroundColor: darkMode ? "#424242 !important" : "#ffffff",
                color: darkMode ? "#ffffff" : "#000000",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
              color: darkMode ? "#ffffff" : "#000000",
            }}
          >
            Add Item
          </DialogTitle>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "end",
              display: "flex",
              mt: -7.5,
            }}
          >
            <Icon
              sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#000000" }}
              onClick={onClose}
            >
              close
            </Icon>
          </DialogTitle>
          <DialogContent>
            <AddInventoryDialog
              newProductName={newProductName}
              onClose={onClose}
              businessId={businessId}
              restaurantsId={
                location.pathname.split("/").slice(-2)[0]
                  ? location.pathname.split("/").slice(-2)[0]
                  : restaurantsId
              }
            />
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openEditInventory}
          onClose={() => setOpenEditInventory(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                // height: "50%",
                maxWidth: "570px",
                minHeight: "40%",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            Edit Item
          </DialogTitle>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "end",
              display: "flex",
              mt: -7.5,
            }}
          >
            <Icon sx={{ cursor: "pointer" }} onClick={onClose}>
              close
            </Icon>
          </DialogTitle>
          <DialogContent>
            <EditInventoryDialog
              productName={productName}
              businessId={businessId}
              productId={productId}
              restaurantsId={
                location.pathname.split("/").slice(-2)[0]
                  ? location.pathname.split("/").slice(-2)[0]
                  : restaurantsId
              }
              onClose={onClose}
            />
          </DialogContent>
          {/* businessId={businessId} onClose={closeAfterMove} selectTab={selectTab} reloadApiAfterClose={reloadApiAfterClose} */}
        </Dialog>
      </MDBox>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default CheckItemExistDialog;
