import { Button, Card, Divider, Grid, Icon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import UserHoc from "hoc/userHoc";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import DataTableLog from "layouts/admin/components/Tables/DataTable/index.js";
import DashboardLayout from "layouts/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import {GetAccountantBilling,GetCardDetails,GetClientLogsList,GetPaymentagreement,GetUserProfile,GetTransactionsList,fullPaymentProfile} from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import LoaderProfile from "../components/imgLoader/LoaderProfile";
import CardImg from "./CardImg";
import SetupForm from "./SetupForm.js";
import DataTable from "./Tables/DataTable/index";
import { useMaterialUIController } from "context";
import Box from "@mui/material/Box";
import check from "assets/images/logos/successful.png";
import PaymentFailed from "assets/images/logos/PaymentFailed.png";
import logo from "assets/images/logos/paynthr-logo-colored.png";
import { Typography } from "@mui/material";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function Billing() {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const accountAccess = localStorage.getItem('accountAccess');
  const [loading, setLoading] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showClientLogs, setShowClientLogs] = useState(false);
  const [showBillingTable, setShowBillingTable] = useState(false);
  const [lastDigit, setLastDigit] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [country, setCountry] = useState("");
  const [expmonth, setExpmonth] = useState("");
  const [expyear, setExpyear] = useState("");
  const [type, setType] = useState("");
  const [billingList, setBillingList] = useState([]);
  const [invoiceLogId, setInvoiceLogId] = useState("");
  const [clientLogsList, setClientLogsList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [newCardText, setNewCardText] = useState(false);
  const [openNoTransaction ,setOpenNoTransaction]= useState(false);
  const [nextBillAmount, setNextBillAmount] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [totalClients, settotalClients] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [transactionsList ,setTransactionsList] = useState([]);
  const [showTransactionsList ,setShowTransactionsList] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [controller] = useMaterialUIController();
  const [showCardEditSuccess, setShowCardEditSuccess] = useState(false);
  const [showDuePayment ,SetShowDuePayment] = useState(false);
  const [errorMessages ,setErrorMessages] = useState('');
  const [paymentFailedStatus , setPaymentFailedStatus] = useState(false);
  const [paymentSuccessStatus , setPaymentSuccessStatus] = useState(false);
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetPaymentagreement, {
    onLoad: false,
  });
  const [
    { isLoading: isEditCardLoading, isError: isEditCardError, response: EditCardResponse },
    EditCardUserAPICall,
  ] = useAPICall(GetPaymentagreement, { onLoad: false });
  useEffect(() => {
    if (isEditCardLoading) {
      return () => null;
    }
    if (isEditCardError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (EditCardResponse && EditCardResponse.data.isSuccess === true) {
      setClientSecret(EditCardResponse?.data?.data?.clientSecret);
      setTimeout(() => {
        setShowPaymentForm(true);
        setLoadingCircul(false);
      }, 1000);
    }
  }, [EditCardResponse]);
  const [
    { isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },
    DetailsUserAPICall,
  ] = useAPICall(GetCardDetails, { onLoad: false });
  const [
    { isError: isBillingError, isLoading: isBillingLoading, response: Billingresponse },
    AccountantBillingAPICall,
  ] = useAPICall(GetAccountantBilling, { onLoad: false });
  const [
    { isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse },
    getUserDteailsAPICall,
  ] = useAPICall(GetUserProfile, { onLoad: false });
  const [
    {
      isLoading: isGetClientLogsLoading,
      isError: isGetClientLogsError,
      response: userLogsDataResponse,
    },
    GetClientLogsAPICall,
  ] = useAPICall(GetClientLogsList, { onLoad: false });

  const [
    { isLoading: isfullPaymentLoading, isError: isfullPaymentError, response: fullPaymentDataResponse },
    fullPaymentDteailsAPICall,
  ] = useAPICall(fullPaymentProfile, { onLoad: false });

  useEffect(() => {
    if (isfullPaymentLoading) {
      return () => null;
    }

    if (isfullPaymentError) {
      return () => null;
    }

    if (fullPaymentDataResponse && fullPaymentDataResponse.data.isSuccess === true) {
      if(fullPaymentDataResponse?.data?.data?.success == false){
          setErrorMessages(fullPaymentDataResponse?.data?.data?.message);
          setPaymentFailedStatus(true);
      }else{
        setErrorMessages(fullPaymentDataResponse?.data?.data?.message);
        setPaymentSuccessStatus(true);
      }
    }
  }, [fullPaymentDataResponse]);


  const appearance = {
    theme: "stripe",
  };

  const Modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
  }, [loading]);

  const [
    {
      isLoading: isGetTransactionsLoading,
      isError: isGetTransactionsError,
      response: transactionsDataResponse,
    },
    GetTransactionsAPICall,
  ] = useAPICall(GetTransactionsList, { onLoad: false });

  useEffect(() => {
    if (isGetTransactionsLoading) {
      return () => null;
    }

    if (isGetTransactionsError) {
      setErrorMessage(transactionsDataResponse?.data?.message);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoadingCircul(false);
      return () => null;
    }

    if (transactionsDataResponse && transactionsDataResponse.data.isSuccess === true) {
      const data = transactionsDataResponse?.data?.data?.records.map((prop, key) => {
        return {
          title: prop?.title ? prop?.title : '-',
          amount: prop?.amount ? prop?.amount : "0",
          currency: prop?.currency ? prop?.currency : "-",
          status : prop?.status  ? 
          prop?.status == "FAILED" ? 
          <MDTypography
            variant="caption"
            fontWeight="regular"
            fontSize="0.875rem"
            color="error"
          >
            {prop?.status}
          </MDTypography> : 
          <MDTypography
          variant="caption"
          fontWeight="regular"
          fontSize="0.875rem"
          color="success"
        >
          {prop?.status}
        </MDTypography>
        : "-",
          message: prop?.message ? prop?.message : "-",
        };
      });
      setTransactionsList(data);
      if( transactionsDataResponse?.data?.data?.totalRecords == 0){
        setOpenNoTransaction(true);
      }else{
        setShowTransactionsList(true);
      }
      
      setLoadingCircul(false);
    }
  }, [transactionsDataResponse]);

  useEffect(() => {
    if (isGetClientLogsLoading) {
      return () => null;
    }

    if (isGetClientLogsError) {
      setErrorMessage(userLogsDataResponse?.data?.message);
      setLoadingLogs(false);
      setShowClientLogs(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoading(false);
      return () => null;
    }

    if (userLogsDataResponse && userLogsDataResponse.data.isSuccess === true) {
      const data = userLogsDataResponse?.data?.data?.records.map((prop, key) => {
        return {
          clientname: prop?.item?.name ? prop?.item?.name : "-",
          startdate: prop?.billingPeriodStartDate ? prop?.billingPeriodStartDate : "-",
          enddate: prop?.billingPeriodEndDate ? prop?.billingPeriodEndDate : "-",
          totaldays: prop?.billableDays ? prop?.billableDays : "-",
          amount: prop?.amount ? `$${prop?.amount}` : "-",
        };
      });
      setLoadingLogs(false);
      setClientLogsList(data);
    }
  }, [userLogsDataResponse]);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      userDetailsDataResponse?.code === "DATA401"
        ? navigate("/admin/logout")
        : console.log("somting else");
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      const BillingDetails = userDetailsDataResponse?.data?.data?.user;
      setNextBillAmount(BillingDetails?.subscriptions[0]?.nextBillAmount);
      setNextBillingDate(BillingDetails?.subscriptions[0]?.nextBillingDate);
      settotalClients(BillingDetails?.totalClients ? BillingDetails?.totalClients : "0");
      // setNextBillAmount(BillingDetails?.nextBillAmount);
      // setNextBillingDate(BillingDetails?.nextBillingDate);
      // settotalClients(BillingDetails?.totalClients ? BillingDetails?.totalClients : '0');
    }
  }, [userDetailsDataResponse]);

  useEffect(() => {
    if (isBillingLoading) {
      return () => null;
    }
    if (isBillingError) {
      setLoading(false);
      return () => null;
    }
    if (Billingresponse && Billingresponse.data.isSuccess === true) {
      const data = Billingresponse?.data?.data?.records.map((prop, key) => {
        prop?.paymentStatus == "Due" && prop?.billingStatus == "Billed" ? SetShowDuePayment(true) : ''
        return {
          invoice: prop?.invoiceId ? prop?.invoiceId : "-",
          billingdate: prop?.periodStartDate ? prop?.periodStartDate : "-",
          amount: prop?.total ? `$${prop?.total}` : "-",
          clients:
            prop?.invoicedItems >= 1 ? (
              <>
                {setShowBillingTable(true)}
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    sx={{ cursor: "pointer" ,color:"#3118f1" }}
                    onClick={async (value) => {
                      setLoadingLogs(true);
                      setShowClientLogs(true);
                      setInvoiceLogId(prop?.invoiceId);
                      GetClientLogsAPICall({
                        payload: [{ accuntantId: `${prop._id}` }],
                      });
                    }}
                  >
                    {prop?.invoicedItems}
                  </MDTypography>
                </MDBox>
              </>
            ) : (
              prop?.invoicedItems
            ),
          paymentStatus: (prop?.paymentStatus == "Due" && prop?.billingStatus == "Billed" ? 
            <MDBox>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              sx={{ cursor: "pointer" ,color:"#3118f1"}}
              onClick={async (value) => {
                setLoadingCircul(true);
                // setShowClientLogs(true);
                setInvoiceLogId(prop?.invoiceId);
                fullPaymentDteailsAPICall({
                  payload: [{ invoiceId: `${prop.invoiceId}` }],
                });
              }}
            >
              Pay Now
            </MDTypography>
          </MDBox>: prop?.paymentStatus),
          clientsShow: prop?.invoicedItems,
          transactions:(<MDTypography
            variant="body2"
            color="secondary"
            sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
          >
            <Tooltip
              title={'Transaction'}
              placement="top"
              onClick={(value) => {
                setLoadingCircul(true);
                GetTransactionsAPICall({
                  payload: [{ invoiceId: `${prop._id}` }],
                })
              }}
            >
              <Icon sx={darkMode ? { color: "#ffffff" } : {}}>receipt</Icon>
            </Tooltip>
          </MDTypography>)
        };
      });
      setBillingList(data);
      setLoading(false);
    }
  }, [Billingresponse]);

  useEffect(async () => {
    AccountantBillingAPICall({
      payload: [],
    });
    await DetailsUserAPICall({
      payload: [],
    });
    getUserDteailsAPICall({
      payload: [],
    });
    const queryParams = new URLSearchParams(window.location.search);
    const redirectStatus = queryParams.get('redirect_status');
    if(redirectStatus == "succeeded"){
      setShowCardEditSuccess(true);
    }
    setLoading(true);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setClientSecret(response?.data?.data?.clientSecret);
      setTimeout(() => {
        setOpenForm(true);
        setShowPaymentForm(true);
        setLoadingCircul(false);
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    openForm === true && response?.data?.isSuccess === true
      ? setLoading(false)
      : setOpenForm(false);
  }, [response, openForm, showPaymentForm]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setLastDigit(DetailsResponse?.data?.data?.card?.lastFourDigit);
      setCardBrand(DetailsResponse?.data?.data?.card?.brand);
      setType(DetailsResponse?.data?.data?.card?.type);
      setExpyear(DetailsResponse?.data?.data?.card?.exp_year);
      setExpmonth(DetailsResponse?.data?.data?.card?.exp_month);
      setCountry(DetailsResponse?.data?.data?.card?.country);
      setCardHolderName(
        DetailsResponse?.data?.data?.card?.cardHolderName
          ? DetailsResponse?.data?.data?.card?.cardHolderName
          : "---"
      );
      setLoadingCircul(false);
    }
  }, [DetailsResponse]);

  const options = {
    // passing the SetupIntent's client secret
    clientSecret: `${clientSecret}`,
    // Fully customizable with appearance API.
    appearance: appearance,
  };

  const handleAddCard = () => {
    setLoadingCircul(true);
    setAPICall({
      payload: [],
    });
  };

  const handleEditCard =async () => {
    setLoadingCircul(true);
    setShowCardEditSuccess(false);
    await DetailsUserAPICall({
      payload: [],
    });
    const hasParams = window.location.search !== '';
    if (hasParams) {
      window.history.replaceState(null, '', '/accountants/billing');
  }
  };

  const handlePaymentStatus = () => {
    setLoadingCircul(false);
    setPaymentFailedStatus(false);
    setPaymentSuccessStatus(false);
  };

  const handlePaymentStatusSuccess = () => {
    setLoadingCircul(false);
    setPaymentSuccessStatus(false);
    getUserDteailsAPICall({
      payload: [],
    });
    navigate("/accountant/client/list");
  };

  eventBus.$on("OnSubmitClick", (data) => OnSubmitClick(data));
  function OnSubmitClick(data) {
    setShowPaymentForm(false);
    setOpenForm(false);
    setNewCardText(false);
  }

  eventBus.$on("EditPaymentForm", (data) => EditPaymentForm(data));
  function EditPaymentForm(data) {
    setNewCardText(true);
    setLoadingCircul(true);
    EditCardUserAPICall({
      payload: [],
    });
  }

  const BillingTableData = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Clients",
        accessor: "clients",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Transaction",
        accessor: "transactions",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: billingList,
  };

  const BillingTableData1 = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Clients",
        accessor: "clients",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [],
  };
  const dataTableTransactionData = {
    columns: [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Currency",
        accessor: "currency",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: transactionsList,
  };
  const dataTableLogsData = {
    columns: [
      {
        Header: " Client Name",
        accessor: "clientname",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "End Date",
        accessor: "enddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total Days",
        accessor: "totaldays",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: clientLogsList,
  };
  return (
    <>
      {loading ? (
        <MDBox display="flex" justifyContent="center">
          <LoaderProfile />
        </MDBox>
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox>
            <MDBox mx={4}>
              <MDBox lineHeight={0} mt={-6} >
                <MDTypography variant="h5">Billing</MDTypography>
              </MDBox>
              <MDBox my={4}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={3}>
                          {showDuePayment ? 
                        <DefaultInfoCard
                        icon="payment"
                        title="Due payment"
                        description={
                          nextBillingDate == null ? `on  -` : `was on ${nextBillingDate}`
                        }
                        value={nextBillAmount ? `$${nextBillAmount}` : `$0`}
                      /> :
                      <DefaultInfoCard
                      icon="payment"
                      title="Next payment"
                      description={
                        nextBillingDate == null ? `on  -` : `on ${nextBillingDate}`
                      }
                      value={nextBillAmount ? `$${nextBillAmount}` : `$0`}
                    /> 
                        }
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                          <DefaultInfoCard
                            icon="groups"
                            title="Total Clients"
                            description="Active"
                            value={totalClients ? totalClients : 0}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          {DetailsResponse?.data?.data?.card === null ? (
                            <MDBox>
                              <MDBox
                                component="ul"
                                display="flex"
                                flexDirection="column"
                                p={0}
                                m={0}
                              ></MDBox>
                              <Card
                                sx={{
                                  border: 1,
                                  borderColor: "#7239ea",
                                  borderStyle: "dashed",
                                  width: `360px`,
                                  height: `224px`,
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                }}
                              >
                                <MDBox
                                  component="li"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  // bgColor={"#f8f5ff"}
                                  borderRadius="lg"
                                  p={2}
                                  mb={1}
                                  mt={1}
                                >
                                  <MDBox width="100%" display="flex" flexDirection="column">
                                    <MDBox
                                      display="flex"
                                      justifyContent="center"
                                      alignItems={{ xs: "flex-start", sm: "center" }}
                                      flexDirection={{ xs: "column", sm: "row" }}
                                      mb={2}
                                    >
                                      <MDTypography
                                        variant="h6"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                      >
                                        No Card Added Yet!
                                      </MDTypography>
                                    </MDBox>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      size="small"
                                      sx={{
                                        mt: "24%",
                                        width: "45%",
                                        ml: "28%",
                                      }}
                                      onClick={handleAddCard}
                                    >
                                      &nbsp;Add New Card
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </MDBox>
                          ) : (
                            <CardImg
                              number={`************${lastDigit}`}
                              holder={cardHolderName}
                              expires={`${expmonth}/${expyear}`}
                              cardBrand={cardBrand}
                              editCard={true}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Card sx={{ maxHeight: '47vh' ,overflow:'auto', backgroundColor: darkMode ? "#202940 !important" : "#ffffff" }}>
                      <MDBox
                        pt={3}
                        px={2}
                        sx={{ pr: 3, mb: 2, mt: -1 }}
                        component="form"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <MDTypography variant="h5" fontWeight="medium">
                          Billing history
                        </MDTypography>
                        <MDBox px={2} display="flex">
                          <MDBox sx={{ pr: 3, mb: 2 }}>
                            {/* <MDInput type="search" label="Search" variant="standard" /> */}
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      {BillingTableData?.rows.length === 0 ? (
                        <MDBox my={2} mt={-0.5}>
                          <DataTable
                            table={BillingTableData1}
                            entriesPerPage={false}
                            pagination={false}
                            showTotalEntries={true}
                          />
                          <MDTypography
                            variant="body2"
                            color="secondary"
                            sx={{ mb: 5, marginLeft: "43%", fontSize: "1.20rem" }}
                          >
                            No billing history found!
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <MDBox my={2} mt={-0.5}>
                          <DataTable
                            table={BillingTableData}
                            entriesPerPage={false}
                            pagination={true}
                            footer = {false}
                            showTotalEntries={true}
                            showCheckBoxs={false}
                          />
                        </MDBox>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox>
          <Modal
            open={openNoTransaction}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0 }}
                  onClick={() => setOpenNoTransaction(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                No Transaction History
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
              This invoice currently lacks any transaction history. Ensure all payments and adjustments are properly logged. Contact support if needed. Keeping records updated ensures accurate financial tracking.
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 4 }}>
               
              </MDBox>
              <MDBox display="flex" justifyContent="center">
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
        </MDBox>
        <MDBox>
              <Dialog
                open={showPaymentForm}
                // onClose={() => setShowPaymentForm(false)}
                aria-labelledby="alert-dialog-title"
                sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            height: "75%",
                            maxWidth: "600px",
                            backgroundColor: "#424242 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            height: "auto",
                            maxWidth: "600px",
                            // maxHeight: "600px",
                          },
                        },
                      }
                }
              >
                <DialogTitle>
                  {newCardText == true ? "Edit Card Details" : "Add New Card"}
                </DialogTitle>
                <Divider sx={{ mt: -1 }} />
                <DialogContent>
                  <Elements stripe={stripePromise} options={options}>
                    <SetupForm Secret={clientSecret} />
                  </Elements>
                </DialogContent>
              </Dialog>
        </MDBox>
        <MDBox>
              <Dialog
                open={showTransactionsList}
                onClose={() => setShowTransactionsList(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "1000px",
                    },
                  },
                }}
              >
                <DialogTitle>Transactions</DialogTitle>
                <Divider sx={{ mt: -1, mb: -2 }} />
                <DialogContent sx={{ mb: 2 }}>
                  <MDBox my={5} sx={{ mt: 2 }}>
                    <DataTableLog
                      table={dataTableTransactionData}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={false}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowTransactionsList(false)}>Close</Button>
                </DialogActions>
              </Dialog>
        </MDBox>
        <MDBox>
              <Dialog
                open={showClientLogs}
                onClose={() => setShowClientLogs(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "1000px",
                    },
                  },
                }}
              >
                <DialogTitle>Invoice #{invoiceLogId}: clients</DialogTitle>
                <Divider sx={{ mt: -1, mb: -2 }} />
                <DialogContent sx={{ mb: 2 }}>
                  {loadingLogs && (
                    <MDBox display="flex" justifyContent="center">
                      <LoaderProfile />
                    </MDBox>
                  )}
                  <MDBox my={5} sx={{ mt: 2 }}>
                    <DataTableLog
                      table={dataTableLogsData}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowClientLogs(false)}>Close</Button>
                </DialogActions>
              </Dialog>
        </MDBox>
        <MDBox>
        <Modal
            open={showCardEditSuccess}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={check} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Card Updated Successfully
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
                Your card details have been updated successfully. You can now continue using our services without any interruption.
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 5 ,mb:-1 }}>
              <MDButton
                variant="gradient"
                color="info"
                // size="small"
                onClick={handleEditCard}
              >
               Ok
              </MDButton>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
              </MDBox>
            </Box>
        </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={paymentSuccessStatus}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={check} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Payment Successful
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1  ,display:'flex' , justifyContent:'center'}}>
                {errorMessages}
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 5 ,mb:-1 }}>
              <Button onClick={handlePaymentStatusSuccess}>Close</Button>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={paymentFailedStatus}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={PaymentFailed} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Payment Failed
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1  ,display:'flex' , justifyContent:'center'}}>
                {errorMessages}
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 5 ,mb:-1 }}>
              <Button onClick={handlePaymentStatus}>Close</Button>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed."
                content={errorMessage}
                // autoHideDuration={3000}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
        </MDBox>
        </MDBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default UserHoc(Billing);
