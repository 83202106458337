import {
    Pagination, PaginationItem,
    ThemeProvider,
    createMuiTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useAsyncDebounce, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { eventBus } from 'utils/eventbus';
import CustomUncheckedIcon from './CustomUnchecked';
import DataTableBodyCell from "./Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "./Tables/DataTable/DataTableHeadCell";
import { useMaterialUIController } from "context";


const theme = createMuiTheme({
    props: {
        MuiCheckbox: {
            icon: <CustomUncheckedIcon />,
        },
    },
    overrides: {
        MuiCheckbox: {
            root: {
                '&.Mui-disabled': {
                    pointerEvents: 'auto',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    cursor: 'not-allowed',
                    // This syntax is necessary (instead of just ".MuiSvgIcon-root") because of the nested theme causing the global class names to be suffixed)
                    '& [class*=MuiSvgIcon-root] .innerBox': {
                        fill: '#eee',
                    },
                },
            },
        },
    },
});
function DataTable({
    entriesPerPage,
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    response,
    selectTab,
    showCheckBoxs,
    showCheck,
    ClientHeigth
}) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const [newpageIndex, setNewpageIndex] = React.useState(0);
    const [selectAllButton, setSelectAllButton] = useState(false);
    const [selectWidth, setSelectwidh] = useState(75);
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries.map((el) => el.toString())
        : ["5", "10", "15", "20", "25"];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    let pageOptions1 = [];
    let pageOptionsFirst = [];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;


    useEffect(() => {
        const ThisOffsethandle = localStorage.getItem("offset1")
        if (newpageIndex === 0) {
            setNewpageIndex(0)
            localStorage.setItem("offset1", 0)
            localStorage.setItem("newpageIndex1", 0)
        } else {
            const num = ThisOffsethandle.toString();
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            // localStorage.setItem("newpageIndex", Number(ThisOffsethandle == 0 ? 0 : result))
            setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result))
        }
    }, [])

    eventBus.$on('showSelectTabs', (data) => showSelectTabs(data));
    function showSelectTabs(data) {
        setNewpageIndex(0);
    }
    const totalRecords = response
    for (let i = 0; i < totalRecords;) {
        i = i + 10
        pageOptionsFirst.push(i);
    }
    pageOptionsFirst.map((prop, key) => {
        const num = prop.toString();
        if (num >= 100) {
            const str = num.substring(0, num.length - 1);
            pageOptions1.push(str - 1)
        } else {
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            pageOptions1.push(result - 1)
        }
    })
    // console.log("pageOptionsFirst", pageOptionsFirst.length)
    eventBus.$on('MoveMenuResponseComplete', (data) => MoveMenuResponseComplete(data));
    function MoveMenuResponseComplete(data) {
        const finaldata = (Number(data) - 10).toString()
        const str = finaldata.split('').reverse().join('')
        const result = str.replace(/^0+/, '');
        setNewpageIndex(Number(result));
    }

    eventBus.$on('showInvoiceStatus', (data) => showInvoiceStatus(data));
    function showInvoiceStatus(data) {
        const found1 = data?.find(obj => {
            return obj.ocrStatus === 'INPROGRESS';
        });
        if (found1) {
            setSelectAllButton(true);
        } else {
            setSelectAllButton(false);
        }
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ des, indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef
            const checkDes = des === 'In Progress' || des === 'Synced to Quickbooks' ? true : false;

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])
            return (
                <ThemeProvider theme={theme}>
                    <MDBox display="flex" alignItems="center">
                        <Checkbox ref={resolvedRef} disabled={checkDes} {...rest} />
                        <MDBox ml={1}>
                            <MDTypography variant="caption" fontWeight="medium" color="text">
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </ThemeProvider>
            )
        }
    )
    const ALLselectTab = true;
    const tableInstance = useTable(
        { columns, data, selectTab, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        onPageChange = (eventBus.$emit('ChangePaginationPage', pageIndex)),
    } = tableInstance;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    // const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        var tempId = [];
        tempId = selectedFlatRows.map(
            d => d.original.id
        )
        // setSelectedRows(tempId);

        eventBus.$emit('invoiceRowSelected', { selectedRowIds: tempId });
    }, [selectedRowIds]);


    const setEntriesPerPage = (value) => setPageSize(value);

    // Render the paginations gotoPage(Number(option))
    const renderPagination = pageOptions1.map((option) => (
        <MDPagination
            item
            key={option}
            onClick={() => eventBus.$emit('onclickchangePage', Number(option)) || setNewpageIndex(Number(option))}
            active={newpageIndex === option}
        >
            {option + 1}
        </MDPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) =>
        value > pageOptions1.length || value < 0 ? gotoPage(0) : gotoPage(Number(value)
        );


    // Customized page options starting from 1
    const customizedpageOptions1 = pageOptions1.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) =>
        gotoPage(Number(value.value - 1)
        );

    // Search input value state
    const [search, setSearch] = useState(globalFilter);
    const [pages, setPages] = useState(1);
    const ThisOffsetuse = localStorage.getItem("offset1");
    const finalPage = ThisOffsetuse == 0 ? 1 : pages;
    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions1.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }


    const handleChange = (e, p) => {
        setPages(p);
        eventBus.$emit('onclickchangePagesDilaog', Number(p - 1)) || setNewpageIndex(Number(p - 1))
    };

    return (
        <TableContainer sx={{ boxShadow: "none" ,backgroundColor: darkMode ? '#202940' : '#ffffff'   }} >
            {/* <EnhancedTableToolbar numSelected={SelectedRows.length} /> */}
            {/* {entriesPerPage || canSearch ? (
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <MDBox display="flex" alignItems="center">
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                            <MDTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                            </MDTypography>
                        </MDBox>
                    )}
                    {canSearch && (
                        <MDBox width="12rem" ml="auto">
                            <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    )}
                </MDBox>
            ) : null} */}
            <Table {...getTableProps()} >
                <MDBox component="thead" >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? "auto" : "auto"}
                                    align={showCheckBoxs === false ? "left" : "center"}
                                    // ml={showCheck == false ? -60 : 0}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}

                        </TableRow>
                    ))}
                </MDBox>
                <TableBody  {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow  {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <DataTableBodyCell
                                        showCheckBoxs={showCheckBoxs}
                                        ClientHeigth={ClientHeigth}
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={showCheckBoxs === false ? "left" : "center"}
                                        ml={-60}
                                        selectHeight={selectTab}
                                        // width={cell.column.Header === 'Vendor' ? 300 : "max - content"}
                                        // display={cell.column.Header === 'Vendor' ? "block" : 'inline-block'}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {
                response == 0 && <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "5%", marginBottom: "5%" }}
                >
                    No records found
                </MDTypography>
            }
            <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={"end"}
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions1.length === 1 ? 0 : 3}
            >
                {/* {showTotalEntries && (
                    <MDBox mb={{ xs: 3, sm: 0 }}>
                        <MDTypography variant="button" color="secondary" fontWeight="regular">
                            Showing {(pages - 1) == 0 ? '01' : `${(pages - 1)}1`} to {`${pages}0`} of {response} entries
                        </MDTypography>
                    </MDBox>
                )} */}

                <>
                    {response >= 1 &&
                        < MDBox sx={{ mr: 1.2, mb: -1.2 }}>
                            <MDTypography variant="button" color={darkMode ? "light" :"secondary"} fontWeight="regular">
                                Showing {(pages - 1) == 0 ? '1' : `${(pages - 1)}1`} to {`${pages}0`} of {response} entries
                            </MDTypography>
                        </MDBox>}
                    {pageOptions1.length > 1 && (
                        <MDPagination
                            variant={pagination.variant ? pagination.variant : "gradient"}
                            color={pagination.color ? pagination.color : "info"}
                        >
                            {
                                // renderPagination.length > 6 ? (
                                //     <MDBox width="5rem" mx={1}>
                                //         <MDInput
                                //             inputProps={{ type: "number", min: 1, max: customizedpageOptions1.length }}
                                //             value={customizedpageOptions1[pageIndex]}
                                //             onChange={(handleInputPagination, handleInputPaginationValue)}
                                //         />
                                //     </MDBox>
                                // ) :
                                // (
                                //     renderPagination
                                // )
                            }
                            {
                                <Pagination
                                    // defaultPage={pages}
                                    page={finalPage}
                                    // sx={{
                                    //     ".MuiPaginationItem-root": { color: "green" }
                                    // }}
                                    // page={pages}
                                    color="primary" // Choose the color for the buttons (primary, secondary, default)
                                    sx={{ justifyContent: 'center', marginTop: 2 }} // Apply styles to the pagination container
                                    onChange={handleChange}
                                    count={pageOptionsFirst.length}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                            sx={{
                                                color: '#237dea', // Set the text color
                                                '&.Mui-selected': {
                                                    backgroundColor: '#237dea', // Set the background color for the selected button
                                                    color: 'white', // Set the text color for the selected button
                                                },
                                            }}
                                            components={{
                                                next: () => <>Next</>,
                                                previous: () => <>Previous</>
                                            }}
                                        />
                                    )}
                                />
                            }
                        </MDPagination>
                    )}
                </>

            </MDBox>
        </TableContainer >
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
