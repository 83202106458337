// import React from 'react';
// import { PaymentElement } from '@stripe/react-stripe-js';

// const SetupForm = () => {
//     console.log("handle open card");
//     return (
//         <form>
//             <PaymentElement />
//             <button>Submit</button>
//         </form>
//     );
// };

// export default SetupForm;

import React, { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import MDButton from "components/MDButton";
import PaymentStatus from "./PaymentStatus";
import { eventBus } from "utils/eventbus";
import { DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import { ErrorMessage, Field } from "formik";
import MDTypography from "components/MDTypography";
import { SITE_URL } from "helpers/config";

const SetupForm = (Secret) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${SITE_URL}/accountants/billing`,
      },
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("succees");
    }
    await stripe.retrieveSetupIntent(`${Secret.Secret}`).then(({ setupIntent }) => {
      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Success! Your card has been saved.");
          break;

        case "processing":
          setMessage("Processing your card. We'll update you when processing is complete.");
          break;

        case "requires_payment_method":
          setMessage("Please try another card.");
          break;
      }
    });
  };
  return (
    <MDBox sx={{ mx: 4 }}>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {errorMessage && (
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errorMessage}
            </MDTypography>
          </MDBox>
        )}
        {message && (
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {message}
            </MDTypography>
          </MDBox>
        )}
        <DialogActions>
          <MDButton onClick={() => eventBus.$emit("OnSubmitClick", "click")}>Close</MDButton>
          <MDButton variant="gradient" color="info" type="submit" sx={{ display: "flex" }}>
            Submit
          </MDButton>
        </DialogActions>
      </form>
    </MDBox>
  );
};

export default SetupForm;
